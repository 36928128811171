import { Injectable } from '@angular/core';
//import { ReplaySubject, Observable } from "rxjs/Rx";
import { Subject, Observable } from "rxjs/Rx";

export enum MessageType {
  BusEvent  = "Bus Event",
  BeginRatableEdit = "Begin Ratable Edit",
  BeginTextEdit = "Begin Text Edit",
  SaveStart = "Save Start",
  SaveDone  = "Save Done",
  SaveError = "Save Error",
  DialogCancel = "Dialog Cancel",
  TextDialogCancel = "Text Dialog Cancel",
  TextSaveStart = "Text Save Start",
  ReloadEvaluation = "Reload Evaluation",
  OpenRatableInfoDialog = "Open Ratable Info Dialog"
}

export class MessageEvent<T> {
  messageType: MessageType;
  data: T
}

@Injectable()
export class MessageBusService {

  busSubject: Subject<MessageEvent<any>> = new Subject<MessageEvent<any>>();

  constructor() { }

  /**
  * send a message via the bus
  * @returns a reference to the message you sent
  */
  publish(me: MessageEvent<any>): MessageEvent<any> {
    console.log("message bus published: ");
    console.log(me);
    this.busSubject.next(me);
    return me;
  }

  publishReloadMessage(anId: number) {
    this.publish({
      messageType: MessageType.ReloadEvaluation,
      data: anId
    });
  }

  get(): Observable<MessageEvent<any>> {
    return this.busSubject;
  }

}
