import { Injectable } from "@angular/core";
import { Observable, Subject, ReplaySubject } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { MessageBusService, MessageType } from "./shared/message-bus/message-bus.service";

import { catInfo } from "./data/default";
import { standards } from "./data/default";
import { defaultStandards } from "./data/default";
import { defaultG100Standards } from "./data/default";
// import { evaluations } from "./data/default";

import { User } from "./shared/user.type";
import { BasicEvalInfo } from "./shared/basic-eval-info.type";

import * as _ from "lodash";

import { Rating } from "./shared/ratable.type";

const G3_STATUS_MAP = {
  "SD": "Private Draft",
  "CP": "HR Review",
  "CE": "Discussion",
  "A" : "Abandoned",
  "FI": "Finalized"
};

const G3_STATUS_INFO = [
      { stateCode: "SD", stateName: "Private Draft", supNext: "CP" },
      { stateCode: "CP", stateName: "HR Review", supNote: "Awaiting Review Completion"},
      { stateCode: "CE", stateName: "Discussion", supNext:"FI"},
      { stateCode: "A",  stateName: "Abandoned"},
      { stateCode: "FI", stateName: "Finalized"} ];

const G100_STATUS_INFO = [
      { stateCode: "DRF", stateName: "Draft", supNext:"REV" },
      { stateCode: "REV", stateName: "In Review", supNote: "Awaiting Review Completion"},
      { stateCode: "DISC", stateName: "Discussion", supNext:"PFS"},
      { stateCode: "PFS",  stateName: "Prep for Signatures", 
        supNote: "Awaiting Signature Prep"},
      { stateCode: "SIGN", stateName: "Signing", supNote: "Awaiting Signature"}, 
      { stateCode: "DONE", stateName: "Done"} ];

const REDACTED_MODES = [ "SD", "CP", "A", "DRF", "REV" ];

const STATUS_INFO_MAP = {
  "g3": G3_STATUS_INFO,
  "g100": G100_STATUS_INFO
};

/**
* function to get the status name.  Expects to be added as a method
* to an evaluation object (or at least something with a structureVersion
* and an evalStatus property).
* FIXME: reacts badly if the evalStatus is invalid for the structureVersion
*/
const getStatus = function() {
  return STATUS_INFO_MAP[this.structureVersion]
    // .filter(i => i.stateCode === this.evalStatus)[0].stateName;
    .filter(i => i.stateCode === this.evalStatus)[0];
};

@Injectable()
export class DbService {

  readonly LOCAL_STORAGE_KEY = "mockEvals";

  catInfo  = catInfo;
  private currentUserEmpno = 4;

  currentUserSubject = new ReplaySubject<User>(1);
  mockEvalsSubject   = new ReplaySubject<any>(1);
  deptSubject        = new ReplaySubject<any>(1);
  //deptLoaded         = false;

  constructor(private http: HttpClient, private messageBus: MessageBusService) {
    this.loadMockEvaluations();
    this.loadCurrentUser();
  }

  private loadCurrentUser() {
    this.http.get<any>("assets/people.json")
      .map(dataArray => dataArray.filter(user => user.empno === this.currentUserEmpno))
      .map(filteredArray => filteredArray[0])
      .subscribe(user => this.currentUserSubject.next(user));
  }

  /**
  * get an object describing the valid status codes etc for a given
  * structureVersion
  */
  public getStatusInfo(aStructureVersion: string): Array<any> {
    return STATUS_INFO_MAP[aStructureVersion];
  }

  /**
  * get the status info object for a given status and version
  */

  public getStatus(aStructureVersion: string, aStatusCode: string) {
    return STATUS_INFO_MAP[aStructureVersion]
      .filter(i => i.stateCode === aStatusCode)[0];
  }

  /**
  */

  private loadDeptInfo() {
    this.http.get<any>("assets/deptInfo.json")
      .subscribe(deptArray => {
        // this.deptArray = deptArray;
        // this.deptLoaded = true;
        this.deptSubject.next(deptArray);
        console.log("loaded dept");
      });
  }

  /**
  * store the mock data in local storage
  */
  private storeMocks(mockArray: Array<any>) {
    window.localStorage.setItem(this.LOCAL_STORAGE_KEY, 
      JSON.stringify(mockArray));
  }

  /**
  * store the current mock evaluation array to local storage
  * NOTE this is just for testing, so concerns about race conditions
  * might be out of place (and since it is in memory I think there 
  * wouldn't be race conditions, but thought I'd mention it for
  * completeness sake
  */

  private storeCurrentMocks() {
    this.getAllEvaluations().subscribe(evalArray => this.storeMocks(evalArray));
  }

  /**
  * get mocks from local storage.  If there is actual data there, 
  * we augment the evals with the getStatus() function.
  */
  private getStoredMocks(): Array<any> {
    let mocks = JSON.parse(window.localStorage.getItem(this.LOCAL_STORAGE_KEY));
    if ( mocks ) {
      console.log("adding getStatus() to mocks...");
      mocks.forEach(e => e.getStatus = getStatus);
    }
    return mocks;
  }

  /**
   * Load the mock evaluations from the static jason data
   * while this happens asynchronously, it should happen quickly
   * upon first load of the app in simulation mode, but it doesn't
   * happen fast enough to prevent problems for initial loads of
   * data, so active stuff is subject based for now
   * instead for now.
   * FIXME the above is possibly out of data -- this function is 
   * evolving 
   */

  private loadMockEvaluations() {
    this.http.get<any>("assets/mock.evals.json")
      .subscribe(evalArray => {

        let localStoreArray = this.getStoredMocks();

        if ( localStoreArray === null ) {
          console.log("storing initial mock data to local storage");
          this.storeMocks(evalArray);
          localStoreArray = this.getStoredMocks();
        } else {
          console.log("local storage data ready -- initial data ignored");
        }

        this.mockEvalsSubject.next(localStoreArray);
        this.messageBus.publishReloadMessage(0);
      });
  }

  /**
  * Remove scores and text if in Draft mode
  * alters the passed object
  */

  private redactAsNecessary(evaluation: any) {
    if (this.currentUserEmpno !== evaluation.empno ) {
      console.log("not current user, no redaction needed");
      return evaluation;
    } else {
      console.log("current user, must redact if mode requires it");
      if ( _.includes(REDACTED_MODES, evaluation.evalStatus) ) {
        console.log("mode requires redaction: " + evaluation.evalStatus);
        return this.redact(evaluation);
      } else {
        console.log("mode does not require redaction: " + evaluation.evalStatus);
        return evaluation;
      }
    }
  }

  /**
  * actually modify the evaluation to remove scores, etc
  */

  private redact(evaluation: any) {
    console.log("redacting");
    evaluation.functions.forEach(f => {
      f.rating = "";
      f.ratingMod = "";
    });
    evaluation.cats.forEach( c=> {
      c.rating = "";
      c.ratingMod = "";
    });
    const fieldsToBlank = [
      "overallRating", "overallRatingMod", "supportComments", 
      "futurePlans", "functionComments", "coreValuesComments"];
    fieldsToBlank.forEach(f => evaluation[f] = "");
    console.log(evaluation);
    return evaluation;
  }

  retrieveEvaluation(id: number): Observable<any> {
    return this.getEvaluation(id)
      .map(e => _.cloneDeep(e))
      .map(e => this.redactAsNecessary(e));
  }

  private getEvaluation(id: number): Observable<any> {

    return this.mockEvalsSubject.asObservable()
      .do(evaluations => { 
        console.log("searching ");
        console.log(evaluations);
      })
      .map(evaluations => evaluations.filter(e => e.id === +id)[0])
      .do( evaluation => {
          console.log("found");
          console.log(evaluation);
      });

  }

  /**
  * get the information for a specified dept code
  */
  getDeptInfo(aDeptCode: string): Observable<any> {
    console.log("In getDeptInfo with aDeptCode of " + aDeptCode);
    return this.http.get<any>("assets/deptInfo.json")
      .do(depts => {
        console.log("searching for dept");
        console.log(depts);
      })
      .map(depts => depts.filter(d => d.deptCode === aDeptCode)[0])
      .do(dept => {
          console.log("dept found");
          console.log(dept);
      });
  }

  /**
   * return a list of evaluations which have the specified supervisor as sup
   * @param empno employee number of the supervisor
   */
  getBasicInfoBySupervisor(empno: number): Observable<any> {
    return this.mockEvalsSubject.asObservable()
      .map(evaluations => evaluations.filter(e => e.supEmpno === empno));
  }

  /**
  * get the evaluations indirectly supervised by the specified employee 
  * number. Does NOT include those directly supervised
  */
  getIndirectEvals(empno: number): Observable<any> {

    // quick in memory hack function to find indirect supervised.
    // anyone supervised more than 5 levels deep will be missed.
    const findIndirect = evaluations => {
      let supEmpnos = evaluations.filter(e => e.supEmpno == empno)
        .map(e => e.empno);
      let indirect = [];
      for (let i=0; i < 5; i++) {
        console.log("checking indirect round " + i);
        let newSupEmpnos = evaluations
          .filter(e => _.includes(supEmpnos, e.supEmpno))
          .map(e => e.empno);
        supEmpnos = _.uniq(supEmpnos.concat(newSupEmpnos));
        console.log("after indirect round " + i + " supEmpnos are ");
        console.log(supEmpnos);
      }
      console.log("after all rounds supEmpnos are: ");
      console.log(supEmpnos);
      return evaluations.filter(e => _.includes(supEmpnos, e.supEmpno));
    };

    return this.mockEvalsSubject.asObservable()
      .map(evaluations => findIndirect(evaluations));
  }

  /**
   * return the evaluations for the specified employee
   * @param empno employee number of the employee
   */
  getBasicInfoByEmpno(empno: number): Observable<any> {
    return this.mockEvalsSubject.asObservable()
      .map(evaluations => evaluations.filter(e => e.empno === empno));
  }

  getCatInfo() {
    return Observable.of(this.catInfo);
  }

  getEvcStandards() {
    return Observable.of(standards);
  }

  getDefaultStandards() {
    return Observable.of(defaultStandards);
  }

  getDefaultG100Standards() {
    return Observable.of(defaultG100Standards);
  }

  /**
   * return the specified function object from the evaluation
   * @param spa the evaluation
   * @param anId the id of the function to return
   */
  private getFunction(spa: any, anId: number) {
    const numFunctions = spa.functions.length;
    for (let i = 0; i < numFunctions; i++) {
        if ( spa.functions[i].id == anId) {
            return spa.functions[i];
        }
    }
    return undefined;
  }

  /**
   * return the first evaluation for the current user
   * FIXME -- currently first just in the sense first in the array
   */
  getLatestEvalForCurrentUser(): Observable<any> {
    return this.getCurrentUser().mergeMap(user => {
        return this.getBasicInfoByEmpno(user.empno)
          .map(infoList => infoList[0]);
    });
  }

  getAllUsers(): Observable<User[]> {
    console.log("getAllUsers called");
    return this.http.get<User[]>("assets/people.json");
  }

  getAllEvaluations(): Observable<any> {
    return this.mockEvalsSubject.asObservable();
  }

  getCurrentUser(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  /**
   * change the current user
   * @param newEmpno empno of the new user identity
   * FIXME -- this is for simulation ONLY
   */
  setCurrentUser(newEmpno: number): void {
    this.currentUserEmpno = newEmpno;
    this.loadCurrentUser();
  }

  /**
   * change the evalStatus of the specified evaluation
   * @param spa: data on the evaluation to change
   */

   setStatus(evalId: any, newStatus: string): Observable<any> {
      console.log("changing eval status of id " + evalId +
        " to " + newStatus);
      return this.getEvaluation(evalId)
        .do(evaluation => evaluation.evalStatus = newStatus)
        .do(e => this.storeCurrentMocks());
   }

  /**
   * return a reference to the category object specified
   * @param spa the evaluations
   * @param aCatNum the category number
   */
  private getCategory(spa: any, aCatNum: number) {
    console.log("running new getCategory");
    const numCats = spa.cats.length;
    for (let i = 0; i < numCats; i++) {
        // FIXME ? why is + conversion needed here?
        if ( +spa.cats[i].categoryNum == aCatNum) {
            return spa.cats[i];
        }
    }
    return undefined;
  }

  updateTextField(evalId: number, fieldName: string, newValue: string): Observable<any> {
    return this.getEvaluation(evalId)
      .do(evaluation => {
          evaluation[fieldName] = newValue;
      })
      .do(e => this.storeCurrentMocks());
  }

  updateFunction(evalId: number, fnId: number, rating: Rating): Observable<any> {
    return this.getEvaluation(evalId)
      .do(evaluation => {
        const f  = this.getFunction(evaluation, fnId);
        if ( f ) {
          f.rating = rating.rating;
          f.importance = rating.importance;
        }
      })
      .do(e => this.storeCurrentMocks());
  }

  /**
   * change the value of the category described by the new value
   * @param spa the evaluation
   * @param newValue information including the new value and a ratableId property with the id
   */
  private updateCatValue(spa: any, newValue: any): void {
    const c = this.getCategory(spa, newValue.ratableId);
    if (c) {
      Object.assign(c, newValue);
    } else {
        console.log("ERR: We didn't find expected cat for " + newValue.editId);
    }
  }

  updateCat(evalId: number, newValue: any): Observable<any> {
    return this.getEvaluation(evalId)
      .do( evaluation => {
        this.updateCatValue(evaluation, newValue);
      })
      .do( e => this.storeCurrentMocks());
  }

  updateOverall(evalId: number, newValue: any): Observable<any> {
    return this.getEvaluation(evalId)
      .do(evaluation => {
        evaluation.overallRating = newValue.rating;
      })
      .do(e => this.storeCurrentMocks());
  }

  updateTrainingCert(evalId: number, newValue: any): Observable<any> {
    return this.getEvaluation(evalId)
      .do(evaluation => evaluation.trainingCert = newValue)
      .do(e => this.storeCurrentMocks());
  }

  updateEvalsConductedCert(evalId: number, newValue: any): Observable<any> {
    return this.getEvaluation(evalId)
      .do(evaluation => evaluation.evalsConductedCert = newValue)
      .do(e => this.storeCurrentMocks());
  }
}
