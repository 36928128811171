export var mockEvals = [
    {
        "selfAccomplishments": "",
        "empno": 1,
        "overallRatingMod": "",
        "workingTitle": "Wizard V",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Elrond",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "de Rivendell",
        "fname": "Gandalf",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "King V",
        "department": "Nine Walkers",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 100,
        "titlecode": "F003",
        "supEmpno": 2,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "Supervise wizards",
                "description": "Supervise Wizards",
                "taskGroup": "",
                "importance": "",
                "id": 101,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Fight Orcs",
                "description": "Fight Orcs",
                "taskGroup": "",
                "importance": "",
                "id": 102,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Cast Spells",
                "description": "Cast Spells",
                "taskGroup": "",
                "importance": "",
                "id": 103,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000002",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            },
            {
                "categoryNum": "10",
                "description": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated.",
                "categoryTitle": "Leadership",
                "coreId": 139,
                "importance": "2",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated.",
                "rating": "",
                "title": "Leadership",
                "ratingMod": ""
            },
            {
                "categoryNum": "11",
                "description": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
                "categoryTitle": "Performance Management",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
                "rating": "",
                "title": "Performance Management",
                "ratingMod": ""
            },
            {
                "categoryNum": "12",
                "description": "Delegating authority consistent with the UCSD Principles of Accountability.",
                "categoryTitle": "Organizational Accountability",
                "coreId": 139,
                "importance": "1",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Delegating authority consistent with the UCSD Principles of Accountability.",
                "rating": "",
                "title": "Organizational Accountability",
                "ratingMod": ""
            },
            {
                "categoryNum": "13",
                "description": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
                "categoryTitle": "Resource Management and Planning",
                "coreId": 139,
                "importance": "2",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
                "rating": "",
                "title": "Resource Management and Planning",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "Grahame",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 2,
        "overallRatingMod": "",
        "workingTitle": "King V",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "JRR",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "Tokien",
        "fname": "Elrond",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "Author",
        "department": "Rivendell",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 104,
        "titlecode": "F005",
        "supEmpno": 9999,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "Return",
                "description": "Return",
                "taskGroup": "",
                "importance": "",
                "id": 105,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Fight Orcs",
                "description": "Fight Orcs",
                "taskGroup": "",
                "importance": "",
                "id": 106,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Lead Armies",
                "description": "Lead Armies",
                "taskGroup": "",
                "importance": "",
                "id": 107,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000001",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            },
            {
                "categoryNum": "10",
                "description": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated.",
                "categoryTitle": "Leadership",
                "coreId": 139,
                "importance": "2",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated.",
                "rating": "",
                "title": "Leadership",
                "ratingMod": ""
            },
            {
                "categoryNum": "11",
                "description": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
                "categoryTitle": "Performance Management",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
                "rating": "",
                "title": "Performance Management",
                "ratingMod": ""
            },
            {
                "categoryNum": "12",
                "description": "Delegating authority consistent with the UCSD Principles of Accountability.",
                "categoryTitle": "Organizational Accountability",
                "coreId": 139,
                "importance": "1",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Delegating authority consistent with the UCSD Principles of Accountability.",
                "rating": "",
                "title": "Organizational Accountability",
                "ratingMod": ""
            },
            {
                "categoryNum": "13",
                "description": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
                "categoryTitle": "Resource Management and Planning",
                "coreId": 139,
                "importance": "2",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
                "rating": "",
                "title": "Resource Management and Planning",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "de Rivendell",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 3,
        "overallRatingMod": "",
        "workingTitle": "Hobbit",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Frodo",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "Baggins",
        "fname": "Sam",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g100",
        "supervisorTitle": "Lead Hobbit",
        "department": "Shire Folk",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 108,
        "titlecode": "F002",
        "supEmpno": 4,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "eat 5 meals daily",
                "description": "eat 5 meals daily",
                "taskGroup": "",
                "importance": "",
                "id": 109,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "walk quietly",
                "description": "walk quietly",
                "taskGroup": "",
                "importance": "",
                "id": 110,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000003",
        "cats": [
            {
                "categoryNum": "1001",
                "description": "A customer is anyone that a UC San Diego employee interacts with, including but not limited to students, fellow staff members, parents, faculty, academics, patients, vendors, affiliates, visitors, and community members. Effective customer service is the ability to meet the needs of others in a timely, efficient, accurate, resourceful, innovative, caring and respectful manner.  To be customer-service oriented is to place customer care at the core and the forefront of our business decisions, to be able to recognize and anticipate customer needs and make every effort to meet and exceed customer expectations. ",
                "categoryTitle": "CUSTOMER SERVICE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "A customer is anyone that a UC San Diego employee interacts with, including but not limited to students, fellow staff members, parents, faculty, academics, patients, vendors, affiliates, visitors, and community members. Effective customer service is the ability to meet the needs of others in a timely, efficient, accurate, resourceful, innovative, caring and respectful manner.  To be customer-service oriented is to place customer care at the core and the forefront of our business decisions, to be able to recognize and anticipate customer needs and make every effort to meet and exceed customer expectations. ",
                "rating": "",
                "title": "CUSTOMER SERVICE",
                "ratingMod": ""
            },
            {
                "categoryNum": "1002",
                "description": "To foster the best possible working and learning environment, UC San Diego strives to maintain a climate of equity, fairness, cooperation, and professionalism. All of us are expected to positively contribute to a climate of integrity marked by mutual respect for each other; celebrate diversity and adapt responsibly to cultural differences; seek to foster understanding and acceptance; promote awareness through education; engage in constructive dialogue and strategies for engaging and resolving conflict; reject any and all acts of discrimination of any kind and appropriately confront and respond to such acts; affirm the right to freedom of expression; maintain and promote an atmosphere free of abusive or demeaning treatment, and commit to adherence and enforcement of all UC San Diego policies and procedures that promote the fulfillment of these principles.",
                "categoryTitle": "PRINCIPLES OF COMMUNITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "To foster the best possible working and learning environment, UC San Diego strives to maintain a climate of equity, fairness, cooperation, and professionalism. All of us are expected to positively contribute to a climate of integrity marked by mutual respect for each other; celebrate diversity and adapt responsibly to cultural differences; seek to foster understanding and acceptance; promote awareness through education; engage in constructive dialogue and strategies for engaging and resolving conflict; reject any and all acts of discrimination of any kind and appropriately confront and respond to such acts; affirm the right to freedom of expression; maintain and promote an atmosphere free of abusive or demeaning treatment, and commit to adherence and enforcement of all UC San Diego policies and procedures that promote the fulfillment of these principles.",
                "rating": "",
                "title": "PRINCIPLES OF COMMUNITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "1003",
                "description": "Safety and environmental issues are essential elements of ensuring the continued success of UC San Diego and its employees. To ensure that everyone has an opportunity to contribute to the University�s mission, each of us must be afforded a safe, healthy, and environmentally sound workplace. We are all responsible for ensuring that policies, practices, services, and behaviors support accepted and current safety, health, and environmental standards.",
                "categoryTitle": "HEALTH AND SAFETY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Safety and environmental issues are essential elements of ensuring the continued success of UC San Diego and its employees. To ensure that everyone has an opportunity to contribute to the University�s mission, each of us must be afforded a safe, healthy, and environmentally sound workplace. We are all responsible for ensuring that policies, practices, services, and behaviors support accepted and current safety, health, and environmental standards.",
                "rating": "",
                "title": "HEALTH AND SAFETY",
                "ratingMod": ""
            },
            {
                "categoryNum": "1004",
                "description": "UC San Diego managers and designated supervisors play a crucial leadership role ensuring the effectiveness and productivity of their respective units.  Through data analysis, strategic thinking and decision-making regarding current and future use of resources, coaching, mentoring, and day-to-day interface with direct reports and other key stakeholders, the primary role and function of the leader/manager/supervisor is to enable achievement of the mission and goals of the department and University.  This is accomplished through utilizing their knowledge, skills, and subject matter expertise in efforts to support and inspire employees to consistently achieve or surpass performance expectations and/or results.",
                "categoryTitle": "LEADERSHIP, MANAGEMENT, SUPERVISION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "UC San Diego managers and designated supervisors play a crucial leadership role ensuring the effectiveness and productivity of their respective units.  Through data analysis, strategic thinking and decision-making regarding current and future use of resources, coaching, mentoring, and day-to-day interface with direct reports and other key stakeholders, the primary role and function of the leader/manager/supervisor is to enable achievement of the mission and goals of the department and University.  This is accomplished through utilizing their knowledge, skills, and subject matter expertise in efforts to support and inspire employees to consistently achieve or surpass performance expectations and/or results.",
                "rating": "",
                "title": "LEADERSHIP, MANAGEMENT, SUPERVISION",
                "ratingMod": ""
            },
            {
                "categoryNum": "1005",
                "description": "",
                "categoryTitle": "STEWARDSHIP OF RESOURCES",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "",
                "rating": "",
                "title": "STEWARDSHIP OF RESOURCES",
                "ratingMod": ""
            },
            {
                "categoryNum": "1006",
                "description": "",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "1007",
                "description": "",
                "categoryTitle": "ORGANIZATIONAL INSIGHT / BUSINESS JUDGMENT",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "",
                "rating": "",
                "title": "ORGANIZATIONAL INSIGHT / BUSINESS JUDGMENT",
                "ratingMod": ""
            },
            {
                "categoryNum": "1008",
                "description": "",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "1009",
                "description": "",
                "categoryTitle": "RELATIONSHIP BUILDING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "",
                "rating": "",
                "title": "RELATIONSHIP BUILDING",
                "ratingMod": ""
            },
            {
                "categoryNum": "1010",
                "description": "",
                "categoryTitle": "PERFORMANCE MANAGEMENT",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "",
                "rating": "",
                "title": "PERFORMANCE MANAGEMENT",
                "ratingMod": ""
            },
            {
                "categoryNum": "1011",
                "description": "",
                "categoryTitle": "VALUING EQUITY, DIVERSITY, INCLUSION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "",
                "rating": "",
                "title": "VALUING EQUITY, DIVERSITY, INCLUSION",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "Gamgee",
        "timeSupervised": "",
        "evalStatus": "SD",
        "functionComments" : "",
        "coreValuesComments": ""
    },
    {
        "selfAccomplishments": "",
        "empno": 4,
        "overallRatingMod": "",
        "workingTitle": "Lead Hobbit",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Gandalf",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "Grahame",
        "fname": "Frodo",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "Wizard V",
        "department": "Shire Folk",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 111,
        "titlecode": "F001",
        "supEmpno": 1,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "supervise other hobbits",
                "description": "supervise other hobbits",
                "taskGroup": "",
                "importance": "",
                "id": 112,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "train others to walk quietly",
                "description": "train others to walk quietly",
                "taskGroup": "",
                "importance": "",
                "id": 113,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000003",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            },
            {
                "categoryNum": "10",
                "description": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated.",
                "categoryTitle": "Leadership",
                "coreId": 139,
                "importance": "2",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated.",
                "rating": "",
                "title": "Leadership",
                "ratingMod": ""
            },
            {
                "categoryNum": "11",
                "description": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
                "categoryTitle": "Performance Management",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
                "rating": "",
                "title": "Performance Management",
                "ratingMod": ""
            },
            {
                "categoryNum": "12",
                "description": "Delegating authority consistent with the UCSD Principles of Accountability.",
                "categoryTitle": "Organizational Accountability",
                "coreId": 139,
                "importance": "1",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Delegating authority consistent with the UCSD Principles of Accountability.",
                "rating": "",
                "title": "Organizational Accountability",
                "ratingMod": ""
            },
            {
                "categoryNum": "13",
                "description": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
                "categoryTitle": "Resource Management and Planning",
                "coreId": 139,
                "importance": "2",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
                "rating": "",
                "title": "Resource Management and Planning",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "Baggins",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 5,
        "overallRatingMod": "",
        "workingTitle": "Hobbit",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Elrond",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "de Rivendell",
        "fname": "Bilbo",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "King V",
        "department": "Shire Folk",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 114,
        "titlecode": "F002",
        "supEmpno": 2,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "eat 5 meals daily",
                "description": "eat 5 meals daily",
                "taskGroup": "",
                "importance": "",
                "id": 115,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "walk quietly",
                "description": "walk quietly",
                "taskGroup": "",
                "importance": "",
                "id": 116,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000003",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "Baggins",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 6,
        "overallRatingMod": "",
        "workingTitle": "Hobbit",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Frodo",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "Baggins",
        "fname": "Merry",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "Lead Hobbit",
        "department": "Shire Folk",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 117,
        "titlecode": "F002",
        "supEmpno": 4,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "eat 5 meals daily",
                "description": "eat 5 meals daily",
                "taskGroup": "",
                "importance": "",
                "id": 118,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "walk quietly",
                "description": "walk quietly",
                "taskGroup": "",
                "importance": "",
                "id": 119,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000003",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "Brandybuck",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 7,
        "overallRatingMod": "",
        "workingTitle": "Hobbit",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Frodo",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "Baggins",
        "fname": "Pippen",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "Lead Hobbit",
        "department": "Shire Folk",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 120,
        "titlecode": "F002",
        "supEmpno": 4,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "eat 5 meals daily",
                "description": "eat 5 meals daily",
                "taskGroup": "",
                "importance": "",
                "id": 121,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "walk quietly",
                "description": "walk quietly",
                "taskGroup": "",
                "importance": "",
                "id": 122,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000003",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "Took",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 8,
        "overallRatingMod": "",
        "workingTitle": "King V",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Elrond",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "de Rivendell",
        "fname": "Aragorn",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "King V",
        "department": "Nine Walkers",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 123,
        "titlecode": "F005",
        "supEmpno": 2,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "Return",
                "description": "Return",
                "taskGroup": "",
                "importance": "",
                "id": 124,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Fight Orcs",
                "description": "Fight Orcs",
                "taskGroup": "",
                "importance": "",
                "id": 125,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Lead Armies",
                "description": "Lead Armies",
                "taskGroup": "",
                "importance": "",
                "id": 126,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000002",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            },
            {
                "categoryNum": "10",
                "description": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated.",
                "categoryTitle": "Leadership",
                "coreId": 139,
                "importance": "2",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated.",
                "rating": "",
                "title": "Leadership",
                "ratingMod": ""
            },
            {
                "categoryNum": "11",
                "description": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
                "categoryTitle": "Performance Management",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
                "rating": "",
                "title": "Performance Management",
                "ratingMod": ""
            },
            {
                "categoryNum": "12",
                "description": "Delegating authority consistent with the UCSD Principles of Accountability.",
                "categoryTitle": "Organizational Accountability",
                "coreId": 139,
                "importance": "1",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Delegating authority consistent with the UCSD Principles of Accountability.",
                "rating": "",
                "title": "Organizational Accountability",
                "ratingMod": ""
            },
            {
                "categoryNum": "13",
                "description": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
                "categoryTitle": "Resource Management and Planning",
                "coreId": 139,
                "importance": "2",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
                "rating": "",
                "title": "Resource Management and Planning",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "Son of Arathorn",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 9,
        "overallRatingMod": "",
        "workingTitle": "Elf III",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Aragorn",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "Son of Arathorn",
        "fname": "Legolas",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "King V",
        "department": "Nine Walkers",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 127,
        "titlecode": "F004",
        "supEmpno": 8,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "Sing",
                "description": "Sing",
                "taskGroup": "",
                "importance": "",
                "id": 128,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Fight Orcs",
                "description": "Fight Orcs",
                "taskGroup": "",
                "importance": "",
                "id": 129,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000002",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "Greenleaf",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 10,
        "overallRatingMod": "",
        "workingTitle": "Elf III",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Elrond",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "de Rivendell",
        "fname": "Arwen",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "King V",
        "department": "Rivendell",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 130,
        "titlecode": "F004",
        "supEmpno": 2,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "Sing",
                "description": "Sing",
                "taskGroup": "",
                "importance": "",
                "id": 131,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Fight Orcs",
                "description": "Fight Orcs",
                "taskGroup": "",
                "importance": "",
                "id": 132,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000001",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "Udomiel",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 11,
        "overallRatingMod": "",
        "workingTitle": "Elf III",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Elrond",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "de Rivendell",
        "fname": "Glorfindel",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "King V",
        "department": "Rivendell",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 133,
        "titlecode": "F004",
        "supEmpno": 2,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "Sing",
                "description": "Sing",
                "taskGroup": "",
                "importance": "",
                "id": 134,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Fight Orcs",
                "description": "Fight Orcs",
                "taskGroup": "",
                "importance": "",
                "id": 135,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000001",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "de Rivendell",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 12,
        "overallRatingMod": "",
        "workingTitle": "Elf III",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "Elrond",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "de Rivendell",
        "fname": "Cirdan",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "King V",
        "department": "Nine Walkers",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 136,
        "titlecode": "F004",
        "supEmpno": 2,
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "Sing",
                "description": "Sing",
                "taskGroup": "",
                "importance": "",
                "id": 137,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Fight Orcs",
                "description": "Fight Orcs",
                "taskGroup": "",
                "importance": "",
                "id": 138,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000002",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "de Havens",
        "timeSupervised": "",
        "evalStatus": "SD"
    },
    {
        "selfAccomplishments": "",
        "empno": 9999,
        "overallRatingMod": "",
        "workingTitle": "Author",
        "restrictedAccessCode": "",
        "signatures": [],
        "supportComments": "",
        "supervisorRole": "",
        "dateHired": "1/1/1939",
        "supFname": "",
        "employeeComments": "",
        "uuid": "",
        "timeSupervisedYears": "2",
        "restrictedAccess": false,
        "unitDivisionLabel": "Unit",
        "jdVersionId": "",
        "supLname": "",
        "fname": "JRR",
        "hrStatus": "",
        "division": "",
        "structureVersion": "g3",
        "supervisorTitle": "",
        "department": "",
        "timeSupervisedMonths": "3",
        "selfGoals": "",
        "jobDescNo": "",
        "id": 139,
        "titlecode": "F006",
        "supEmpno": "",
        "supid": "",
        "cycle": "2017/18",
        "functionReviewStatus": "",
        "selfEvalStatus": "",
        "jobDescVersion": "",
        "periodStart": "5/1/2017",
        "functions": [
            {
                "name": "Write",
                "description": "Write",
                "taskGroup": "",
                "importance": "",
                "id": 140,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            },
            {
                "name": "Smoke Pipe",
                "description": "Smoke Pipe",
                "taskGroup": "",
                "importance": "",
                "id": 141,
                "rating": "",
                "percent": "",
                "title": "",
                "ratingMod": ""
            }
        ],
        "deptCode": "000000",
        "cats": [
            {
                "categoryNum": "1",
                "description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "categoryTitle": "JOB KNOWLEDGE",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job.",
                "rating": "",
                "title": "JOB KNOWLEDGE",
                "ratingMod": ""
            },
            {
                "categoryNum": "2",
                "description": "Evaluate the accuracy, completeness, and follow-through of work.",
                "categoryTitle": "QUALITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the accuracy, completeness, and follow-through of work.",
                "rating": "",
                "title": "QUALITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "3",
                "description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "categoryTitle": "PLANNING/ORGANIZING",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources.",
                "rating": "",
                "title": "PLANNING/ORGANIZING",
                "ratingMod": ""
            },
            {
                "categoryNum": "4",
                "description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "categoryTitle": "PRODUCTIVITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities.",
                "rating": "",
                "title": "PRODUCTIVITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "5",
                "description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "categoryTitle": "INITIATIVE/INNOVATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position.",
                "rating": "",
                "title": "INITIATIVE/INNOVATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "6",
                "description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "categoryTitle": "TEAMWORK/COOPERATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner.",
                "rating": "",
                "title": "TEAMWORK/COOPERATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "7",
                "description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "categoryTitle": "DEPENDABILITY",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision.",
                "rating": "",
                "title": "DEPENDABILITY",
                "ratingMod": ""
            },
            {
                "categoryNum": "8",
                "description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "categoryTitle": "COMMUNICATION",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner.",
                "rating": "",
                "title": "COMMUNICATION",
                "ratingMod": ""
            },
            {
                "categoryNum": "9",
                "description": "Summary Rating for all UCSD Campus-Wide Standards.",
                "categoryTitle": "UCSD STANDARDS",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": " ",
                "categoryDesc": "Summary Rating for all UCSD Campus-Wide Standards.",
                "rating": "",
                "title": "UCSD STANDARDS",
                "ratingMod": ""
            },
            {
                "categoryNum": "10",
                "description": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated.",
                "categoryTitle": "Leadership",
                "coreId": 139,
                "importance": "2",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated.",
                "rating": "",
                "title": "Leadership",
                "ratingMod": ""
            },
            {
                "categoryNum": "11",
                "description": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
                "categoryTitle": "Performance Management",
                "coreId": 139,
                "importance": "3",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements.",
                "rating": "",
                "title": "Performance Management",
                "ratingMod": ""
            },
            {
                "categoryNum": "12",
                "description": "Delegating authority consistent with the UCSD Principles of Accountability.",
                "categoryTitle": "Organizational Accountability",
                "coreId": 139,
                "importance": "1",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Delegating authority consistent with the UCSD Principles of Accountability.",
                "rating": "",
                "title": "Organizational Accountability",
                "ratingMod": ""
            },
            {
                "categoryNum": "13",
                "description": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
                "categoryTitle": "Resource Management and Planning",
                "coreId": 139,
                "importance": "2",
                "categoryGroup": "SUPERVISOR",
                "categoryDesc": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals.",
                "rating": "",
                "title": "Resource Management and Planning",
                "ratingMod": ""
            }
        ],
        "overallRating": "",
        "periodEnd": "4/30/2017",
        "selfEvalNotice": "",
        "futurePlans": "",
        "lname": "Tokien",
        "timeSupervised": "",
        "evalStatus": "SD"
    }
];
