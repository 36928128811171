import { MessageType, MessageEvent } from "./message-bus.service";

export class TextSaveStartMessage {
  textValue: string;
  fieldName: string;
  id: string;

  static event(tssm: TextSaveStartMessage): MessageEvent<TextSaveStartMessage> {
    return {
      messageType: MessageType.TextSaveStart,
      data: tssm
    };
  }
}
