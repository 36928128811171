import { Component, OnInit, OnDestroy } from '@angular/core';
import { DbService } from "../../db.service";

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit, OnDestroy {

  employees: any[];
  dbSub: any;

  constructor(private dbService: DbService) { }

  ngOnInit() {
    this.dbSub = this.dbService.getAllUsers().subscribe ( users => this.employees = users);
  }

  ngOnDestroy() {
      // fix me -- getting not a function error on this
      // this.dbSub.unSubcribe();
  }

}
