import { Component, OnInit, Input } from '@angular/core';
import { MessageBusService, MessageType } from "../shared/message-bus/message-bus.service";
import { RatableType, getScoreOptions } from "../shared/ratable.type";

@Component({
  selector: 'overall-rating',
  templateUrl: './overall-rating.component.html',
  styleUrls: ['./overall-rating.component.css']
})
export class OverallRatingComponent implements OnInit {

  @Input() evaluation;
  @Input() mode: string;

  constructor(private messageBus: MessageBusService) { }

  ngOnInit() {
  }

  beginEdit(viewOnly: boolean) {
    console.log("beginEdit in overall called ");

    this.messageBus.publish( {
      messageType: MessageType.BeginRatableEdit,
      data: {
        scoreOptions:  getScoreOptions(this.evaluation.structureVersion),
        evalId: this.evaluation.id,
        ratableId: 0,
        ratableType: RatableType.Overall,
        title: "Overall Rating",
        description: "Overall Rating",
        rating: this.evaluation.overallRating,
        ratingMod: this.evaluation.ratingMod,
        importance: this.evaluation.importance,
        noImportanceFlag: true,
        viewOnly: viewOnly
      }
    });
  }
}
