import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { ModalModule, AlertModule, TooltipModule, BsDropdownModule } from "ngx-bootstrap";

import { AppComponent } from "./app.component";

import { StatusControlComponent } from "./status-control/status-control.component";
import { EvalHeaderComponent } from "./eval-header/eval-header.component";
import { FunctionReviewComponent } from "./function-review/function-review.component";
import { PerfReviewComponent } from "./perf-review/perf-review.component";
import { OverallRatingComponent } from "./overall-rating/overall-rating.component";
import { StdTextComponent } from "./std-text/std-text.component";
import { G3EvalComponent } from "./g3-eval/g3-eval.component";
import { HomeComponent } from "./home/home.component";

import { DbService } from "./db.service";
import { ModalService } from "./shared/modal.service";
import { MessageBusService } from "./shared/message-bus/message-bus.service";

import { routing } from "./app.routes";
import { SupListComponent } from "./sup-list/sup-list.component";
import { RateableEditorComponent } from "./rateable-editor/rateable-editor.component";
import { EvalTextEditorComponent } from "./eval-text-editor/eval-text-editor.component";
import { EmployeeActionsComponent } from './employee-actions/employee-actions.component';
import { G100EvalComponent } from './g100-eval/g100-eval.component';

import { SharedModule } from "./shared/shared.module";
import { AdminModule } from "./admin/admin.module";
import { CertsComponent } from './certs/certs.component';

@NgModule({
  declarations: [
    AppComponent,
    StatusControlComponent,
    EvalHeaderComponent,
    FunctionReviewComponent,
    PerfReviewComponent,
    OverallRatingComponent,
    StdTextComponent,
    G3EvalComponent,
    HomeComponent,
    SupListComponent,
    RateableEditorComponent,
    EvalTextEditorComponent,
    EmployeeActionsComponent,
    G100EvalComponent,
    CertsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    SharedModule,
    AdminModule,
    routing
  ],
  entryComponents: [RateableEditorComponent, EvalTextEditorComponent],
  providers: [DbService, MessageBusService, ModalService],
  exports: [AlertModule, ModalModule, TooltipModule, BsDropdownModule ],
  bootstrap: [AppComponent]
})
export class AppModule { }
