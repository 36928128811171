import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DbService } from "../db.service";

@Component({
  selector: 'status-control',
  templateUrl: './status-control.component.html',
  styleUrls: ['./status-control.component.css']
})
export class StatusControlComponent implements OnInit {

  @Input() evaluation;
  //@Output() statusChange = new EventEmitter<string>();

  constructor(private dbService: DbService) { }

  ngOnInit() {
  }

  moveToState(newStatus: string) {
    //this.statusChange.emit("change");
    console.log("moving to status " + newStatus);
    this.dbService.setStatus(this.evaluation.id, newStatus)
      .subscribe(
          () => console.log("and we're done")
      );
  }

  getStatus(aCode: string) {
    return this.dbService.getStatus(this.evaluation.structureVersion, aCode);
  }

}
