import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminHomeComponent } from "./admin-home/admin-home.component";
import { SpaListComponent } from "./spa-list/spa-list.component";

const routes: Routes = [
  {
    path: "admin",
    children: [
      { path: "adminhome", component: AdminHomeComponent  },
      { path: "spalist", component: SpaListComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
