import { Component, OnInit } from '@angular/core';
import { DbService } from "../../db.service";

@Component({
  selector: 'app-spa-list',
  templateUrl: './spa-list.component.html',
  styleUrls: ['./spa-list.component.css']
})
export class SpaListComponent implements OnInit {

  evaluations: any = [];

  constructor(private dbService: DbService) { }

  lookupEvalStatus(aStatus: string, aStructureVersion: string) {
    const filteredStates = this.getStates(aStructureVersion).filter(s => s.stateCode === aStatus);
    if ( filteredStates.length === 0 ) {
      return { stateCode: "U", stateName: "unknown" };
    } else {
      return filteredStates[0];
    }
  }

  getStates(structureVersion: string){
    //return this.g3States;
    return this.dbService.getStatusInfo(structureVersion);
  }

  changeState(spa: any, newState: any) {
    // FIXME -- heavyweight, but avoids having to load just one evaluation into the array for now
    this.dbService.setStatus(spa.id, newState.stateCode)
      .subscribe(evaluation => this.loadEvaluations());
  }

  ngOnInit() {
      this.loadEvaluations();
  }

  private loadEvaluations(): void {
      this.dbService.getAllEvaluations().subscribe(evaluations => this.evaluations = evaluations);
  }

}
