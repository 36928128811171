import { Component, OnInit, Input } from "@angular/core";
import { DbService } from "../db.service";
import { User } from "../shared/user.type";

/**
 * FIXME --  unsubscribe to observables in onDestroy
 */

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {

  user: User = User.empty;
  userDept = { deptName: "blarg"};
  myLatestEval: any = {};
  supervisedEvals: any[];
  indirectEvals: any[];
  allUsers: User[];

  constructor(private dbService: DbService) { }

  changeUser(anEmpno) {
    this.dbService.setCurrentUser(anEmpno);
  }

  ngOnInit() {
    this.dbService.getCurrentUser().subscribe(
      user => {
        console.log("home got user: " + JSON.stringify(user));
        this.user = user;
        this.reloadEvaluations();
        this.reloadDeptInfo();
      },
      error => console.log("home get error on user" + JSON.stringify(error))
    );
    this.dbService.getAllUsers().subscribe(
      users => this.allUsers = users,
      error => console.log("error getting all users from home")
    );
  }

  reloadDeptInfo() {
      console.log("calling reloadDeptInfo");
      this.dbService.getDeptInfo(this.user.deptCode).subscribe(
        deptInfo => {
          this.userDept = deptInfo;
          console.log("GOT dept info is " + JSON.stringify(deptInfo));
        }
      );
  }

  reloadEvaluations() {
    this.dbService.getBasicInfoBySupervisor(this.user.empno).subscribe(
      data => {
        //console.log("got by sup " + JSON.stringify(data));
        this.supervisedEvals = data;
      }
    );
    this.dbService.getIndirectEvals(this.user.empno).subscribe(
      data => {
        //console.log("got indirect by sup " + JSON.stringify(data));
        this.indirectEvals = data;
      }
    );
    this.dbService.getLatestEvalForCurrentUser().subscribe(
      evaluation => {
        //console.log("\n\ngot latest eval by current " + JSON.stringify(evaluation));
        this.myLatestEval = evaluation;
      }
    );
  }

}
