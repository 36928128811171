import { Component, OnInit, Input } from '@angular/core';
import { MessageBusService, MessageType } from "../shared/message-bus/message-bus.service";
import { BeginTextEditMessage } from "../shared/message-bus/begin-text-edit-message.type";

@Component({
  selector: 'employee-actions',
  templateUrl: './employee-actions.component.html',
  styleUrls: ['./employee-actions.component.css']
})
export class EmployeeActionsComponent implements OnInit {

  @Input() evaluation;

  constructor(private messageBus: MessageBusService) { }

  ngOnInit() { }

  beginTextEdit(aFieldName: string, aTitle: string) {
    this.messageBus.publish(BeginTextEditMessage.event({
      title: aTitle,
      fieldName: aFieldName
    }));
  }

}
