export var catsDb = {
    "cats": [
        {
            "id": "1",
            "category_num": "1",
            "category_id": "1",
            "category_title": "JOB KNOWLEDGE",
            "category_description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job."
        },
        {
            "id": "2",
            "category_num": "2",
            "category_id": "2",
            "category_title": "QUALITY",
            "category_description": "Evaluate the accuracy, completeness, and follow-through of work."
        },
        {
            "id": "3",
            "category_num": "3",
            "category_id": "3",
            "category_title": "PLANNING/ORGANIZING",
            "category_description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources."
        },
        {
            "id": "4",
            "category_num": "4",
            "category_id": "4",
            "category_title": "PRODUCTIVITY",
            "category_description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities."
        },
        {
            "id": "5",
            "category_num": "5",
            "category_id": "5",
            "category_title": "INITIATIVE/INNOVATION",
            "category_description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position."
        },
        {
            "id": "6",
            "category_num": "6",
            "category_id": "6",
            "category_title": "TEAMWORK/COOPERATION",
            "category_description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner."
        },
        {
            "id": "7",
            "category_num": "7",
            "category_id": "7",
            "category_title": "DEPENDABILITY",
            "category_description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision."
        },
        {
            "id": "8",
            "category_num": "8",
            "category_id": "8",
            "category_title": "COMMUNICATION",
            "category_description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner."
        },
        {
            "id": "9",
            "category_num": "9",
            "category_id": "9",
            "category_title": "UCSD STANDARDS",
            "category_description": "Summary Rating for all UCSD Campus-Wide Standards."
        },
        {
            "id": "10",
            "category_num": "10",
            "category_id": "10",
            "category_title": "Leadership",
            "category_group": "SUPERVISOR",
            "category_description": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated."
        },
        {
            "id": "11",
            "category_num": "11",
            "category_id": "11",
            "category_title": "Performance Management",
            "category_group": "SUPERVISOR",
            "category_description": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements."
        },
        {
            "id": "12",
            "category_num": "12",
            "category_id": "12",
            "category_title": "Organizational Accountability",
            "category_group": "SUPERVISOR",
            "category_description": "Delegating authority consistent with the UCSD Principles of Accountability."
        },
        {
            "id": "13",
            "category_num": "13",
            "category_id": "13",
            "category_title": "Resource Management and Planning",
            "category_group": "SUPERVISOR",
            "category_description": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals."
        }
    ],
    "lookup": {
        "1": [
            {
                "id": "1",
                "category_num": "1",
                "category_id": "1",
                "category_title": "JOB KNOWLEDGE",
                "category_description": "Evaluate the use of information, procedures, materials, equipment and techniques required for current job."
            }
        ],
        "2": [
            {
                "id": "2",
                "category_num": "2",
                "category_id": "2",
                "category_title": "QUALITY",
                "category_description": "Evaluate the accuracy, completeness, and follow-through of work."
            }
        ],
        "3": [
            {
                "id": "3",
                "category_num": "3",
                "category_id": "3",
                "category_title": "PLANNING/ORGANIZING",
                "category_description": "Consider  effectiveness in response to varying work demands, developing efficient methods, setting goals and objectives, establishing priorities, and utilizing available resources."
            }
        ],
        "4": [
            {
                "id": "4",
                "category_num": "4",
                "category_id": "4",
                "category_title": "PRODUCTIVITY",
                "category_description": "Evaluate the volume and timeliness of work based on the resources available to accomplish department/unit goals and priorities."
            }
        ],
        "5": [
            {
                "id": "5",
                "category_num": "5",
                "category_id": "5",
                "category_title": "INITIATIVE/INNOVATION",
                "category_description": "Evaluate  the self-starting ability, resourcefulness, and creativity to formulate and propose innovative solutions and improvements to the duties of the position."
            }
        ],
        "6": [
            {
                "id": "6",
                "category_num": "6",
                "category_id": "6",
                "category_title": "TEAMWORK/COOPERATION",
                "category_description": "Consider effectiveness of working relationships with other employees, students, and faculty to solve problems, improve work processes, share information and resources, and accomplish specific tasks in a professional and ethical manner."
            }
        ],
        "7": [
            {
                "id": "7",
                "category_num": "7",
                "category_id": "7",
                "category_title": "DEPENDABILITY",
                "category_description": "Consider punctuality, regularity in attendance, meeting deadlines, and performing work without close supervision."
            }
        ],
        "8": [
            {
                "id": "8",
                "category_num": "8",
                "category_id": "8",
                "category_title": "COMMUNICATION",
                "category_description": "Evaluate the clarity of ideas expressed, effectiveness of oral and written presentations, and listening to and interacting with others in a helpful, informative, and professional manner."
            }
        ],
        "9": [
            {
                "id": "9",
                "category_num": "9",
                "category_id": "9",
                "category_title": "UCSD STANDARDS",
                "category_description": "Summary Rating for all UCSD Campus-Wide Standards."
            }
        ],
        "10": [
            {
                "id": "10",
                "category_num": "10",
                "category_id": "10",
                "category_title": "Leadership",
                "category_group": "SUPERVISOR",
                "category_description": "Creating a climate of trust and mutual respect; increasing the potential for employees to be productive and to feel welcome, valued, and motivated."
            }
        ],
        "11": [
            {
                "id": "11",
                "category_num": "11",
                "category_id": "11",
                "category_title": "Performance Management",
                "category_group": "SUPERVISOR",
                "category_description": "Managing employee performance in alignment with the mission and goals of the department or unit and consistent with relevant policies and collective bargaining agreements."
            }
        ],
        "12": [
            {
                "id": "12",
                "category_num": "12",
                "category_id": "12",
                "category_title": "Organizational Accountability",
                "category_group": "SUPERVISOR",
                "category_description": "Delegating authority consistent with the UCSD Principles of Accountability."
            }
        ],
        "13": [
            {
                "id": "13",
                "category_num": "13",
                "category_id": "13",
                "category_title": "Resource Management and Planning",
                "category_group": "SUPERVISOR",
                "category_description": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals."
            }
        ]
    }
};
