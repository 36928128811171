import { Component, OnInit, Input } from "@angular/core";
import { MessageBusService, MessageType } from "../shared/message-bus/message-bus.service";
import { RatableType } from "../shared/ratable.type";

/**
 *  used to manage the functions part of the appraisal
 */
@Component({
  selector: "function-review",
  templateUrl: "./function-review.component.html",
  styleUrls: ["./function-review.component.css"]
})
export class FunctionReviewComponent implements OnInit {

  @Input() evaluation;
  @Input() mode;
  @Input() title;
  @Input() titleDescription;

  constructor(private messageBus: MessageBusService) { }

  ngOnInit() { }

  beginEdit(fn: any, viewOnly = false) {
    console.log("beginEdit in FRC called with viewonly " + viewOnly);
    console.log("beginEdit in FRC called with ");
    console.log(fn);

    this.messageBus.publish( {
      messageType: MessageType.BeginRatableEdit,
      data: {
        evalId: this.evaluation.id,
        ratableId: fn.id,
        ratableType: RatableType.Function,
        title: fn.name,
        description: fn.description,
        rating: fn.rating,
        ratingMod: fn.ratingMod,
        importance: fn.importance,
        viewOnly: viewOnly
      }
    });
  }
}
