import { MessageType, MessageEvent } from "./message-bus.service";

export class BeginTextEditMessage {
  title: string;
  fieldName: string;

  static event(btem: BeginTextEditMessage) {
    return {
      messageType: MessageType.BeginTextEdit,
      data: btem
    };
  }
}
