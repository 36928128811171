import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { DbService } from "../db.service";
import { MessageBusService, MessageType } from "../shared/message-bus/message-bus.service";
import { TextSaveStartMessage } from "../shared/message-bus/text-save-start-message.type";

import { RateableEditorComponent, PerfReviewScoreInfo, TopicType } from "../rateable-editor/rateable-editor.component";
import { EvalTextEditorComponent } from "../eval-text-editor/eval-text-editor.component";
import { Ratable, RatableType, Rating } from "../shared/ratable.type";

@Injectable()
export class ModalService {

  // FIXME -- these should really be somewhere else (they are likely used elsewhere, too)
  private G100_SCORES = ["O", "M", "A", "P", "N"];
  private G3_SCORES =   ["E", "A", "S", "I", "U"];

  private activeModals = {
    ratableModal: undefined,
    textModal:    undefined
  };

  private modalConfig = {
    animated: false,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
    class: "modal-lg"
  };

  constructor(
    private bsModalService: BsModalService,
    private dbService: DbService,
    private messageBus: MessageBusService
  ) {
    this.messageBus.get().subscribe(
      event => {
        switch (event.messageType) {
        case MessageType.DialogCancel:
          this.closeRatableModal();
          break;
        case MessageType.TextDialogCancel:
          this.closeTextModal();
          break;
        case MessageType.TextSaveStart:
          this.saveTextField(event.data);
          break;
        case MessageType.SaveStart:
          this.saveRatable(event.data);
          break;
        default: console.log("ModService ignoring event: ");
          console.log(event);
        }
    });
  }

  openRatableModal(ratable: Ratable, evaluation: any, viewOnly: boolean) {
    console.log(" modal service openRatableModal called with viewOnly of " + viewOnly);
    this.activeModals.ratableModal = this.bsModalService.show(RateableEditorComponent, this.modalConfig);
    this.setRatingTableInModal(ratable);
    this.activeModals.ratableModal.content.initialData = ratable;
    this.activeModals.ratableModal.content.viewOnly = viewOnly;
    this.activeModals.ratableModal.content.employeeName = `${evaluation.fname} ${evaluation.lname}`;
  }

  closeRatableModal() {
    this.activeModals.ratableModal.hide();
    this.activeModals.ratableModal = undefined;
  }

  openTextModal(title: string, fieldName: string, evaluation: any) {
    const tModal = this.bsModalService.show(EvalTextEditorComponent, this.modalConfig);
    this.activeModals.textModal = tModal;
    tModal.content.title = title;
    tModal.content.textValue = evaluation[fieldName];
    tModal.content.fieldName = fieldName;
    tModal.content.id = evaluation.id;
    tModal.content.employeeName = `${evaluation.lname}, ${evaluation.fname}`;
  }

  closeTextModal() {
    this.activeModals.textModal.hide();
    this.activeModals.textModal = undefined;
  }

  private saveTextField(saveData: TextSaveStartMessage) {

    console.log("saving text data from ModalService");
    console.log(saveData);
    this.dbService.updateTextField(+saveData.id, saveData.fieldName, saveData.textValue)
      .subscribe(
        data => {
          this.messageBus.publishReloadMessage(+saveData.id);
          this.closeTextModal();
        },
        error => console.log("error saving " + saveData)
      );

  }

  private saveRatable(saveData: any) {
    switch (saveData.value.ratableType) {
      case RatableType.Category:
        this.saveCat(saveData.value.evalId, saveData.value);
        break;
      case RatableType.Function:
        this.saveFunction(saveData.value.evalId, saveData.value.ratableId, saveData.value);
        break;
      case RatableType.Overall:
        this.saveOverall(saveData.value.evalId, saveData.value);
        break;
      default:
        console.log("unable to save, invalid ratableType: ");
        console.log(saveData);
    }
  }

  private saveOverall(evalId: any, dataToSave: any) {
    this.dbService.updateOverall(evalId, dataToSave)
      .subscribe(
        data => {
          this.messageBus.publishReloadMessage(+evalId);
          this.closeRatableModal();
        },
        error => console.log("error saving overall " + dataToSave)
      );

  }

  saveCat(evalId: any, dataToSave: any) {
    this.dbService.updateCat(evalId, dataToSave)
      .subscribe(
        data => {
          this.messageBus.publishReloadMessage(+evalId);
          this.closeRatableModal();
        },
        error => console.log("error saving " + dataToSave)
      );
  }

  saveFunction(evalId: number, fnId: number, rating: Rating) {
    const msg = "evalId/fnId/rating: " + evalId + "/" + fnId;
    console.log(msg);
    console.log(rating);
    this.dbService.updateFunction(evalId, fnId, rating)
      .subscribe(
        data => {
          this.messageBus.publishReloadMessage(+evalId);
          this.closeRatableModal();
        },
        error => console.log("error saving " + msg)
      );

  }

  // FIXME -- there is duplicate code in the Ratable.Function and Ratable.Overall branches
  // could be a fallthrough, but maybe that would be more confusing?
  // and at least for now a different diagnostic message is being printed making fallthrough
  // more of a problem.
  private setRatingTableInModal(aRatable: Ratable) {
    switch (aRatable.ratableType) {
      case RatableType.Category:
        console.log("getting ratings defs for Category " + aRatable.ratableId);
        const catNum = +aRatable.ratableId;
        this.dbService.getEvcStandards().subscribe(stds =>
          this.activeModals.ratableModal.content.ratingTable = this.getRatingTable(catNum, stds)
        );
        break;
      case RatableType.Function:
        console.log("getting ratings defs for Function");
        if ( aRatable.structureVersion === "g100" ) {
          this.dbService.getDefaultG100Standards().subscribe(stds =>
            this.activeModals.ratableModal.content.ratingTable = this.getDefaultRatingTable(stds, this.G100_SCORES)
          );
        } else {
          this.dbService.getDefaultStandards().subscribe(stds =>
            this.activeModals.ratableModal.content.ratingTable = this.getDefaultRatingTable(stds, this.G3_SCORES)
          );
        }
        break;
      case RatableType.Overall:
        console.log("getting ratings defs for Overall");
        if ( aRatable.structureVersion === "g100" ) {
          this.dbService.getDefaultG100Standards().subscribe(stds =>
            this.activeModals.ratableModal.content.ratingTable = this.getDefaultRatingTable(stds, this.G100_SCORES)
          );
        } else {
          this.dbService.getDefaultStandards().subscribe(stds =>
            this.activeModals.ratableModal.content.ratingTable = this.getDefaultRatingTable(stds, this.G3_SCORES)
          );
        }

        break;
      default:
          console.log("error, no standards lookup for ratableType: " + aRatable.ratableType);
    }

  }

 /**
  * format data from the evc standards info for the modal display and return it
  */
  private getRatingTable(catNum: number, stds: any): Array<PerfReviewScoreInfo> {
    // FIXME -- shouldn't be hardcoded based on catNum
    let scores = this.G3_SCORES;
    if ( catNum > 999 ) {
      scores = this.G100_SCORES;
    }
    return scores.map( r => {
      return {
        rating: r,
        ratingHtml: stds[catNum].scoreInfo[r].map(i => i.ex_text)
      };
    });
  }

  /**
  * format data from the default standards info for the modal display and return it
  * @param stds the standard info object keyed on the codes 
  * @param scores the list of score codes(eg E, A, S, ...) for this type of rating table
  */
  private getDefaultRatingTable(stds: any, scores: string[]): Array<PerfReviewScoreInfo> {
    return scores.map( r => {
      return {
        rating: r,
        ratingHtml: [stds[r]]
      };
    });
  }

}
