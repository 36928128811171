import { Component, OnInit, Input } from "@angular/core";

import { BasicEvalInfo } from "../shared/basic-eval-info.type";

@Component({
  selector: "sup-list",
  templateUrl: "./sup-list.component.html",
  styleUrls: ["./sup-list.component.css"]
})
export class SupListComponent implements OnInit {

  @Input() supervisedEvals: BasicEvalInfo[];
  @Input() indirect: false;

  constructor() { }

  ngOnInit() { }

}
