import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { MessageBusService } from "../shared/message-bus/message-bus.service";
import { DbService } from "../db.service";

@Component({
  selector: 'certs',
  templateUrl: './certs.component.html',
  styleUrls: ['./certs.component.css']
})
export class CertsComponent implements OnInit {

  @Input() evaluation;
  @Input() mode;

  modalRef: BsModalRef;


  constructor(
    private modalService: BsModalService,
    private messageBus: MessageBusService,
    private dbService: DbService) { }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: "modal-med"});
  }

  cancelModal() {
    this.modalRef.hide();
  }

  setEvalsConducted(code: string) {
    // FIXME -- unsubscribe??
    this.dbService.updateEvalsConductedCert(+this.evaluation.id, code)
      .subscribe( e => {
        this.messageBus.publishReloadMessage(+this.evaluation.id);
        this.modalRef.hide();
      });
  }

  setTrainingCert(code: string) {
    // FIXME -- unsubscribe??
    this.dbService.updateTrainingCert(+this.evaluation.id, code)
      .subscribe( e => {
        this.messageBus.publishReloadMessage(+this.evaluation.id);
        this.modalRef.hide();
      });
  }

  ngOnInit() {
  }

}
