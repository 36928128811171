import { Component, TemplateRef, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable } from "rxjs";
import { ActivatedRoute } from '@angular/router';

import { DbService } from "../db.service";
import { MessageBusService, MessageType } from "../shared/message-bus/message-bus.service";

import { nextStateMap } from "../data/default";

import { Ratable, RatableType, getScoreOptions, Rating } from "../shared/ratable.type";
import { ModalService } from "../shared/modal.service";

@Component({
  selector: 'g3-eval',
  templateUrl: './g3-eval.component.html',
  styleUrls: ['./g3-eval.component.css']
})
export class G3EvalComponent implements OnInit, OnDestroy {

  evaluation;
  evalId;
  catInfo;
  private routeSub: any;
  private mode: string; // whether we are emp/sup/hr/other mode
  private messageBusSub: any;
  nextStateMap = nextStateMap;

  constructor(
    private evalModalService: ModalService,
    private route: ActivatedRoute,
    private dbService: DbService,
    private messageBus: MessageBusService) { }

    // FIXME -- this should probably live elsewhere (maybe in the modal service )
    // it is duplicated a bit in g100
  openRatableModal(topic: any) {

    console.log("g3 topic data is " + JSON.stringify(topic));
    const rType = topic.ratableType ? topic.ratableType : RatableType.Category;
    const initialData: Ratable = {
      scoreOptions: getScoreOptions(this.evaluation.structureVersion),
      structureVersion: this.evaluation.structureVersion,
      ratableType: rType,
      evalId: this.evalId,
      ratableId: topic.categoryNum || topic.ratableId,
      title: topic.title,
      description: topic.description,
      importance: topic.importance,
      rating: topic.rating,
      ratingMod: topic.ratingMod,
      ratingOptions: [],
      ratingInfo: [],
      noImportanceFlag: topic.noImportanceFlag
    };
    this.evalModalService.openRatableModal(initialData, this.evaluation, topic.viewOnly);
  }

  private beginTextEdit(editData: any) {
    this.evalModalService.openTextModal(editData.title, editData.fieldName, this.evaluation);
  }

  ngOnInit() {
    console.log("g3 initialized");
    this.routeSub = this.route.params.subscribe( params => {
      console.log("routeSub: " + JSON.stringify(params));
      this.evalId = +params["id"];
      this.mode = params["mode"];
      this.loadEvalFromId();
      /*
      console.log("g3 trying to load from obs with evalId " + this.evalId);
      this.loadFromObservable(this.dbService.retrieveEvaluation(this.evalId));
      this.dbService.getCatInfo().subscribe( ci => this.catInfo = ci);
      */
    });

    this.messageBusSub = this.messageBus.get()
      .subscribe( event => {
          console.log("g3 processing message bus event");
          console.log(event);
          switch (event.messageType) {
            case MessageType.BeginRatableEdit:
              this.openRatableModal(event.data);
              break;
            case MessageType.BeginTextEdit:
              this.beginTextEdit(event.data);
              break;
            case MessageType.ReloadEvaluation:
              this.loadEvalFromId();
              break;
            default:
              console.log("G3 unprocessed event type:");
              console.log(event);
          }
        }
      );
  }

  ngOnDestroy(): void {
    console.log("g3 on destroy called");
    this.routeSub.unsubscribe();
    this.messageBusSub.unsubscribe();
  }

  private loadEvalFromId() {
    console.log("g100 (loadEvalFromId()) to load from obs with evalId " + this.evalId);
    this.loadFromObservable(this.dbService.retrieveEvaluation(this.evalId));
    this.dbService.getCatInfo().subscribe( ci => this.catInfo = ci);
  }

  loadFromObservable(obs: Observable<any>) {
    obs.subscribe(
      e => this.evaluation = e,
      error => console.log("error " + error)
    );
  }

  moveToNextState(msg: string) {
    console.log("got msg: " + msg);
    const nextStatus = this.nextStateMap[this.evaluation.evalStatus];
    if ( nextStatus) {
        this.evaluation.evalStatus = nextStatus;
    }
  }
}