import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";


import { MessageBusService, MessageType } from "../shared/message-bus/message-bus.service";
import { Ratable, ScoreOption, G3_SCORES } from "../shared/ratable.type";
import { waitForMap } from '@angular/router/src/utils/collection';

export interface PerfReviewScoreInfo {
  rating: string,
  ratingHtml: Array<string>
}

export enum TopicType {
  Function = "Function",
  Category = "Category"
}

@Component({
  selector: 'rateable-editor',
  templateUrl: './rateable-editor.component.html',
  styleUrls: ['./rateable-editor.component.css']
})
export class RateableEditorComponent implements OnInit, OnDestroy {

  private _initialData: Ratable;
  private _viewOnly = false;
  formGroup: FormGroup;
  @Input() employeeName: string;
  @Input() ratingTable: any;
  @Input() showImportance: true;
  @Input() scoreOptions: ScoreOption[];
  //@Input() viewOnly = false;

  @Input() set viewOnly(aFlag: boolean) { 
    console.log("view only called with " + aFlag);
    this._viewOnly = aFlag; 
  }
           get viewOnly(): boolean { return this._viewOnly; }

  @Input() set initialData(someData: Ratable) {
    console.log("intial data changed to " + JSON.stringify(someData));
    this._initialData = someData;
    this.scoreOptions = this._initialData.scoreOptions;
    this.formGroup.patchValue(this._initialData);
  }

  get initialData(): Ratable { return this._initialData; }

  constructor(private messageBus: MessageBusService) { }

  ngOnInit() {
    console.log("initial data is " + JSON.stringify(this.initialData));
    this.formGroup = new FormGroup({
      rating:       new FormControl(""),
      importance:   new FormControl(""),
      ratableId:    new FormControl(""),
      evalId:       new FormControl(""),
      ratableType:  new FormControl("")
    });
  }

  ngOnDestroy() {
    console.log("RatableEditorComponent instance destroyed");
  }

  onSubmit() {
    console.log("on submit called");
    console.log(this.formGroup);
    this.messageBus.publish({
      messageType: MessageType.SaveStart,
      data: this.formGroup
    });
  }

  dialogCancel() {
    this.messageBus.publish({
        messageType: MessageType.DialogCancel,
        data: "cancel"
      });
  }

}
