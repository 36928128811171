import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from "ngx-bootstrap";

import { AdminRoutingModule } from './admin-routing.module';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { SpaListComponent } from './spa-list/spa-list.component';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    // FIXME -- forRoot() doesn't seem right here, but it isn't working without re-importing
    BsDropdownModule.forRoot()
  ],
  declarations: [AdminHomeComponent, SpaListComponent]
})
export class AdminModule { }
