import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'rating-def-table',
  templateUrl: './rating-def-table.component.html',
  styleUrls: ['./rating-def-table.component.css']
})
export class RatingDefTableComponent implements OnInit {

  @Input() ratingTable: any;
  @Input() formGroup: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}