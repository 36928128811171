import { Component, OnInit, Input } from "@angular/core";

import { MessageBusService, MessageType } from "../shared/message-bus/message-bus.service";
import { BeginTextEditMessage } from "../shared/message-bus/begin-text-edit-message.type";

@Component({
  selector: "std-text",
  templateUrl: "./std-text.component.html",
  styleUrls: ["./std-text.component.css"]
})
export class StdTextComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() textValue: string;
  @Input() fieldName: string;
  @Input() userType: string; // who edits? sup/emp
  @Input() mode: string; // form mode sup/emp/hr/other
  @Input() useEmbeddedMargins = false;

  bsMargins = "my-4";
  bsPadding = "";

  constructor(private messageBus: MessageBusService) { }

  ngOnInit() {
    console.log("embedded margins = " + this.useEmbeddedMargins);
    if ( this.useEmbeddedMargins) {
      this.bsMargins = "m-1";
      this.bsPadding = "p-1";
    }
  }

  /**
   * split a string into an array of strings using two newlines as the 
   * delimiter.
   * @param aString the string to split
   */
  splitIntoParagraphs(aString): string {
    return aString.split("\n\n");
  }

  beginEdit(aFieldName: string): void {
    this.messageBus.publish( BeginTextEditMessage.event({
      title: this.title,
      fieldName: aFieldName
    }));
  }
}