import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingDefTableComponent } from './widgets/rating-def-table/rating-def-table.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [RatingDefTableComponent],
  exports: [RatingDefTableComponent]
})
export class SharedModule { }
