export var evcStandards = {
    "1": {
        "spe_aa_std_categories_id": 35,
        "category_num": 1,
        "category_id": 1,
        "category_title": "JOB KNOWLEDGE",
        "category_group": "",
        "category_description": "Performance as a result of job knowledge, job knowledge as a resource, and application of knowledge to achieve objectives",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 1,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Has failed to obtain/ maintain job knowledge needed to perform job"
                },
                {
                    "spe_aa_std_category_ex_id": 2,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Asks for help with most projects as knowledge is insufficient to function independently"
                },
                {
                    "spe_aa_std_category_ex_id": 3,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Rarely knows how to apply job knowledge to achieve objectives"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 4,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs to improve job knowledge to perform job satisfactorily"
                },
                {
                    "spe_aa_std_category_ex_id": 5,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Needs to update job knowledge to reduce reliance on others"
                },
                {
                    "spe_aa_std_category_ex_id": 6,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs to improve application of job knowledge to achieve objectives"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 7,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Understands job functions and knows how to fulfill them"
                },
                {
                    "spe_aa_std_category_ex_id": 8,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Keeps current in field, continues to gain knowledge and to share information"
                },
                {
                    "spe_aa_std_category_ex_id": 9,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Applies knowledge for the achievement of objectives"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 10,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Knowledge exceeds what is required to perform well"
                },
                {
                    "spe_aa_std_category_ex_id": 11,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Serves, and is recognized, as a subject matter expert"
                },
                {
                    "spe_aa_std_category_ex_id": 12,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Helps others improve their job knowledge for the successful achievement of team objectives"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 13,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Exhibits superior knowledge and expertise in the most complex aspects of the job"
                },
                {
                    "spe_aa_std_category_ex_id": 14,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Serves as an instructor/mentor"
                },
                {
                    "spe_aa_std_category_ex_id": 15,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Uses knowledge to develop/ promote improvements"
                }
            ]
        }
    },
    "2": {
        "spe_aa_std_categories_id": 36,
        "category_num": 2,
        "category_id": 2,
        "category_title": "QUALITY",
        "category_group": "",
        "category_description": "Producing correct, precise results, working with degree of excellence, and learning from errors",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 16,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Does not check results to ensure completion"
                },
                {
                    "spe_aa_std_category_ex_id": 17,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Quality of outputs does not meet standards"
                },
                {
                    "spe_aa_std_category_ex_id": 18,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Has not demonstrated ability to understand how to improve quality and learn from past errors"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 19,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Inconsistently checks results to ensure completion"
                },
                {
                    "spe_aa_std_category_ex_id": 20,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Needs to improve the quality of work to meet standards"
                },
                {
                    "spe_aa_std_category_ex_id": 21,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs to focus on learning from past errors to reduce error rate"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 22,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Checks results to ensure completion"
                },
                {
                    "spe_aa_std_category_ex_id": 23,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Quality of outputs meets standards"
                },
                {
                    "spe_aa_std_category_ex_id": 24,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Learns from, and avoids duplicating errors"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 25,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Regularly evaluates situation and suggests ways to improve quality"
                },
                {
                    "spe_aa_std_category_ex_id": 26,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Uses information from all sources to achieve thorough and accurate results"
                },
                {
                    "spe_aa_std_category_ex_id": 27,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Uses errors as learning opportunity, sharing with team"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 28,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Develops and implements ways to measure and improve quality"
                },
                {
                    "spe_aa_std_category_ex_id": 29,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Other employees use his/her work as model"
                },
                {
                    "spe_aa_std_category_ex_id": 30,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Mentors/trains others in quality improvement processes"
                }
            ]
        }
    },
    "3": {
        "spe_aa_std_categories_id": 37,
        "category_num": 3,
        "category_id": 3,
        "category_title": "PLANNING/ ORGANIZATION",
        "category_group": "",
        "category_description": "Developing action plans, organizing and prioritizing work, and keeping track of multiple tasks/projects",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 31,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Fails to create action plans to accommodate workload and work schedule"
                },
                {
                    "spe_aa_std_category_ex_id": 32,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Rarely organizes and prioritizes work without direction"
                },
                {
                    "spe_aa_std_category_ex_id": 33,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Has not demonstrated ability to track and complete multiple tasks/projects"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 34,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs to improve ability to create clear, practical action plans"
                },
                {
                    "spe_aa_std_category_ex_id": 35,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Needs to improve organization and prioritization of work"
                },
                {
                    "spe_aa_std_category_ex_id": 36,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs to strengthen concentration when working on multiple tasks/projects"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 37,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Understands objectives and creates clear, thoughtful action plans"
                },
                {
                    "spe_aa_std_category_ex_id": 38,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Prioritizes/organizes work and selects efficient methods/processes"
                },
                {
                    "spe_aa_std_category_ex_id": 39,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Knows status of assigned tasks and location of materials"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 40,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Anticipates barriers and develops contingency plans to overcome them"
                },
                {
                    "spe_aa_std_category_ex_id": 41,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Assists others with planning/ organizing work and resources"
                },
                {
                    "spe_aa_std_category_ex_id": 42,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Assists with the tracking of multiple tasks/projects outside immediate area of responsibility"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 43,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Mentors others on effective planning and organizing"
                },
                {
                    "spe_aa_std_category_ex_id": 44,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Develops plans for unit that maximize results and streamline processes"
                },
                {
                    "spe_aa_std_category_ex_id": 45,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Can successfully manage multiple projects/tasks at the same time"
                }
            ]
        }
    },
    "4": {
        "spe_aa_std_categories_id": 38,
        "category_num": 4,
        "category_id": 4,
        "category_title": "PRODUCTIVITY",
        "category_group": "",
        "category_description": "Using work time to achieve desired objectives, working efficiently with proper tools, overcoming barriers",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 46,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Does not produce agreed results in agreed time frame"
                },
                {
                    "spe_aa_std_category_ex_id": 47,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Has not shown sufficient understanding of best tools and processes to do job"
                },
                {
                    "spe_aa_std_category_ex_id": 48,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Relies on assistance from others to overcome barriers"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 49,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs improvement to produce agreed results in agreed time frame"
                },
                {
                    "spe_aa_std_category_ex_id": 50,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Needs to improve use of tools and processes to complete tasks more efficiently"
                },
                {
                    "spe_aa_std_category_ex_id": 51,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs guidance more often times than not to overcome barriers"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 52,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Produces agreed results in agreed time frame"
                },
                {
                    "spe_aa_std_category_ex_id": 53,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Focuses on tasks and use of the best job tools for tasks"
                },
                {
                    "spe_aa_std_category_ex_id": 54,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Informs supervisor if delays or barriers are encountered"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 55,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Regularly exceeds agreed results and/or time frame"
                },
                {
                    "spe_aa_std_category_ex_id": 56,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Has complete understanding of the best tools and processes to follow for maximum efficiency"
                },
                {
                    "spe_aa_std_category_ex_id": 57,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Assists others on team to overcome barriers and work more efficiently"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 58,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Exceeds agreed results and time frame"
                },
                {
                    "spe_aa_std_category_ex_id": 59,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Coaches others on the use of the best tools and processes to follow for maximum efficiency"
                },
                {
                    "spe_aa_std_category_ex_id": 60,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Anticipates unforeseen workload needs and finds ways to meet them"
                }
            ]
        }
    },
    "5": {
        "spe_aa_std_categories_id": 39,
        "category_num": 5,
        "category_id": 5,
        "category_title": "INITIATIVE/INNOVATION",
        "category_group": "",
        "category_description": "Taking action without needing direction to do so;Adapting to change, finding new methods, and contributing to results",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 61,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Fails to take action even when directed to do so"
                },
                {
                    "spe_aa_std_category_ex_id": 62,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Does not contribute to process improvement"
                },
                {
                    "spe_aa_std_category_ex_id": 63,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Does not recognize or support needs in areas of responsibility"
                },
                {
                    "spe_aa_std_category_ex_id": 64,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "U",
                    "ex_num": 3,
                    "ex_text": "Inflexible and negative when faced with change"
                },
                {
                    "spe_aa_std_category_ex_id": 65,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "U",
                    "ex_num": 4,
                    "ex_text": "Has not demonstrated willingness or ability to find/ learn new methods to improve processes"
                },
                {
                    "spe_aa_std_category_ex_id": 66,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "U",
                    "ex_num": 5,
                    "ex_text": "Needs to be led through the change process to produce desired results"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 67,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Relies on direction before taking action"
                },
                {
                    "spe_aa_std_category_ex_id": 68,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Does not routinely contribute to process improvement"
                },
                {
                    "spe_aa_std_category_ex_id": 69,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs to improve ability to recognize and support needs in areas of responsibility"
                },
                {
                    "spe_aa_std_category_ex_id": 70,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "I",
                    "ex_num": 3,
                    "ex_text": "Needs to be more flexible and positive to adapt to change"
                },
                {
                    "spe_aa_std_category_ex_id": 71,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "I",
                    "ex_num": 4,
                    "ex_text": "Does not go out of one's way to support or learn new approaches"
                },
                {
                    "spe_aa_std_category_ex_id": 72,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "I",
                    "ex_num": 5,
                    "ex_text": "Rarely contributes ideas/ suggestions/solutions to achieve results"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 73,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Builds on suggestions of others and puts ideas into action"
                },
                {
                    "spe_aa_std_category_ex_id": 74,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Evaluates current processes and suggests new methods/ improvements"
                },
                {
                    "spe_aa_std_category_ex_id": 75,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Recognizes and meets needs in areas of responsibility"
                },
                {
                    "spe_aa_std_category_ex_id": 76,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "S",
                    "ex_num": 3,
                    "ex_text": "Adapts to change readily"
                },
                {
                    "spe_aa_std_category_ex_id": 77,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "S",
                    "ex_num": 4,
                    "ex_text": "Seeks learning opportunities that foster new approaches"
                },
                {
                    "spe_aa_std_category_ex_id": 78,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "S",
                    "ex_num": 5,
                    "ex_text": "Provides ideas/suggestions/ solutions within immediate area of responsibility"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 79,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes action whenever the opportunity arises"
                },
                {
                    "spe_aa_std_category_ex_id": 80,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Monitors results to ensure desired outcome"
                },
                {
                    "spe_aa_std_category_ex_id": 81,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Recognizes and meets needs outside areas of responsibility"
                },
                {
                    "spe_aa_std_category_ex_id": 82,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Generates enthusiasm for new ideas"
                },
                {
                    "spe_aa_std_category_ex_id": 83,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Thinks creatively to find better, more efficient ways to do things within immediate area of responsibility"
                },
                {
                    "spe_aa_std_category_ex_id": 84,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Assesses results and develops plans for improvements"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 85,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Shows strong initiative in every situation"
                },
                {
                    "spe_aa_std_category_ex_id": 86,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Contributes to process improvement irrespective of individual benefit(s)"
                },
                {
                    "spe_aa_std_category_ex_id": 87,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Assesses risks, anticipates consequences and takes preventative action"
                },
                {
                    "spe_aa_std_category_ex_id": 88,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "E",
                    "ex_num": 3,
                    "ex_text": "Develops creative strategies and promotes creative thinking"
                },
                {
                    "spe_aa_std_category_ex_id": 89,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "E",
                    "ex_num": 4,
                    "ex_text": "Implements improvements which, measured by impact, frequency and effort, are successful"
                },
                {
                    "spe_aa_std_category_ex_id": 90,
                    "category_num": 5,
                    "category_id": 5,
                    "rating": "E",
                    "ex_num": 5,
                    "ex_text": "Leads others through change by keeping focused on desired objectives"
                }
            ]
        }
    },
    "6": {
        "spe_aa_std_categories_id": 40,
        "category_num": 6,
        "category_id": 6,
        "category_title": "TEAMWORK/ COOPERATION",
        "category_group": "",
        "category_description": "Working well with others, supporting team missions, and participating fully in shared efforts",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 91,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Obstructs team's progress"
                },
                {
                    "spe_aa_std_category_ex_id": 92,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Does not support and contribute to team mission"
                },
                {
                    "spe_aa_std_category_ex_id": 93,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Has consistently failed to achieve results as team member"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 94,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Does not participate well on teams"
                },
                {
                    "spe_aa_std_category_ex_id": 95,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Motivated more for his/her own goals than for those of the team"
                },
                {
                    "spe_aa_std_category_ex_id": 96,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Has occasionally failed to coordinate with and contribute to work of team"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 97,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Builds rapport, supports suggestions of others and develops shared solutions"
                },
                {
                    "spe_aa_std_category_ex_id": 98,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Is dedicated to fulfilling team mission and objectives"
                },
                {
                    "spe_aa_std_category_ex_id": 99,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Does one's share within immediate area of responsibility"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 100,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Assists others and picks up slack"
                },
                {
                    "spe_aa_std_category_ex_id": 101,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Helps develop team mission/ plans and coordinates resolutions for problems as they occur"
                },
                {
                    "spe_aa_std_category_ex_id": 102,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Helps pull team together and motivates others to work together harmoniously"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 103,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Exemplifies harmonious working relationships"
                },
                {
                    "spe_aa_std_category_ex_id": 104,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Drives and inspires others to exceed team goals"
                },
                {
                    "spe_aa_std_category_ex_id": 105,
                    "category_num": 6,
                    "category_id": 6,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Serves as a team leader, facilitator, or motivator"
                }
            ]
        }
    },
    "7": {
        "spe_aa_std_categories_id": 41,
        "category_num": 7,
        "category_id": 7,
        "category_title": "DEPENDABILITY",
        "category_group": "",
        "category_description": "Being present and accountable, following through, and meeting deadlines",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 106,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Absences have negatively impacted workflow in office"
                },
                {
                    "spe_aa_std_category_ex_id": 107,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Fails to follow through and complete assigned tasks"
                },
                {
                    "spe_aa_std_category_ex_id": 108,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Has failed to consistently meet deadlines"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 109,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs improvement in ensuring coverage during absences to lessen impact on office workflow"
                },
                {
                    "spe_aa_std_category_ex_id": 110,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Needs to improve follow-through and completion of assigned tasks"
                },
                {
                    "spe_aa_std_category_ex_id": 111,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Has occasionally missed deadlines"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 112,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Manages absences to minimize impact on office workflow"
                },
                {
                    "spe_aa_std_category_ex_id": 113,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Conscientious worker who consistently follows through with assignments"
                },
                {
                    "spe_aa_std_category_ex_id": 114,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Meets deadlines or unit standards for timely results"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 115,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Initiates backup coverage plans for planned absences"
                },
                {
                    "spe_aa_std_category_ex_id": 116,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "High level of accountability for projects"
                },
                {
                    "spe_aa_std_category_ex_id": 117,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Does not disappoint when deadlines are tight"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 118,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Serves as model for attendance and punctuality"
                },
                {
                    "spe_aa_std_category_ex_id": 119,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Always delivers on promises"
                },
                {
                    "spe_aa_std_category_ex_id": 120,
                    "category_num": 7,
                    "category_id": 7,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Assists others in meeting/exceeding deadlines and in understanding priorities"
                }
            ]
        }
    },
    "8": {
        "spe_aa_std_categories_id": 42,
        "category_num": 8,
        "category_id": 8,
        "category_title": "COMMUNICATION",
        "category_group": "",
        "category_description": "Listening and exchanging information to achieve work objectives",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 121,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Often misunderstands or does not listen to the ideas/ views of others"
                },
                {
                    "spe_aa_std_category_ex_id": 122,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Hard to understand the points of communication"
                },
                {
                    "spe_aa_std_category_ex_id": 123,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Is unable or unwilling to share or use shared information to achieve work objectives"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 124,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs improvement in listening to and receiving the ideas/ views of others"
                },
                {
                    "spe_aa_std_category_ex_id": 125,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Could learn how to better communicate and articulate thoughts and ideas with others"
                },
                {
                    "spe_aa_std_category_ex_id": 126,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Is occasionally unwilling to share or receive information to achieve work objectives"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 127,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Understands and uses active/reflective listening"
                },
                {
                    "spe_aa_std_category_ex_id": 128,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Expresses things clearly and confirms understanding"
                },
                {
                    "spe_aa_std_category_ex_id": 129,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Anticipates information needs and shares relevant information in a timely way"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 130,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Accurately interprets and conveys to others what is being said"
                },
                {
                    "spe_aa_std_category_ex_id": 131,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Communicates reasons behind facts/results and confirms that others have necessary information"
                },
                {
                    "spe_aa_std_category_ex_id": 132,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Initiates constructive dialogue and guides positive outcomes"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 133,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Mentors others in improving their listening/communication skills"
                },
                {
                    "spe_aa_std_category_ex_id": 134,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Is an articulate spokesperson for team's views"
                },
                {
                    "spe_aa_std_category_ex_id": 135,
                    "category_num": 8,
                    "category_id": 8,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Takes a lead role in fostering appropriate communication amongst involved parties to achieve objectives"
                }
            ]
        }
    },
    "9": {
        "spe_aa_std_categories_id": 51,
        "category_num": 9,
        "category_id": 9,
        "category_title": "9. UCSD STANDARDS",
        "category_group": "",
        "category_description": "Summary Rating for all UCSD Campus-Wide Standards",
        "scoreInfo": {
            "E": [
                {
                    "spe_aa_std_category_ex_id": 256,
                    "category_num": 9,
                    "category_id": 9,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Exceptional Performance well exceeds expectations and is consistently outstanding."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 257,
                    "category_num": 9,
                    "category_id": 9,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Above Expectations. Performance is consistently beyond expectations."
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 258,
                    "category_num": 9,
                    "category_id": 9,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Solid Performance. Performance consistently fulfills expectations and at times exceeds them."
                }
            ],
            "U": [
                {
                    "spe_aa_std_category_ex_id": 259,
                    "category_num": 9,
                    "category_id": 9,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Unsatisfactory. Performance is consistently below expectations. Deficiencies should be addressed as noted in the performance appraisal."
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 260,
                    "category_num": 9,
                    "category_id": 9,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Improvement Needed. Performance does not consistently meet expectations."
                }
            ]
        }
    },
    "10": {
        "spe_aa_std_categories_id": 47,
        "category_num": 10,
        "category_id": 10,
        "category_title": "LEADERSHIP",
        "category_group": "",
        "category_description": "Creating a climate of trust and mutual respect, creating/ articulating unit goals/vision, increasing potential for employees to be productive and to feel welcome, valued and motivated.",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 196,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Has created a climate where staff feel unsupported and distrustful of leadership"
                },
                {
                    "spe_aa_std_category_ex_id": 197,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Appears unable to develop/articulate unit goals/vision"
                },
                {
                    "spe_aa_std_category_ex_id": 198,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Fails to train, motivate, direct and/or develop staff"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 199,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs to change approach so staff feel more supported and trusting of leadership"
                },
                {
                    "spe_aa_std_category_ex_id": 200,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Creates/sets unrealistic unit goals and fails to communicate them with staff"
                },
                {
                    "spe_aa_std_category_ex_id": 201,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs to learn how to be a more effective trainer and motivator to develop and direct staff"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 202,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Creates a climate where staff trusts and supports leadership and each other"
                },
                {
                    "spe_aa_std_category_ex_id": 203,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Involves staff in creating goals/vision and sets reasonable expectations"
                },
                {
                    "spe_aa_std_category_ex_id": 204,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Shows strength in leading, training, motivating, and developing staff"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 205,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Has the confidence of staff because he/she is concerned with staff's personal welfare and development"
                },
                {
                    "spe_aa_std_category_ex_id": 206,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Has a participative approach and manages people's expectations"
                },
                {
                    "spe_aa_std_category_ex_id": 207,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Looks for opportunities to further train, motivate, and develop staff"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 208,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Clearly understands leadership as facilitating the success of team"
                },
                {
                    "spe_aa_std_category_ex_id": 209,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Effectively communicates \"Big Picture\" viewpoint"
                },
                {
                    "spe_aa_std_category_ex_id": 210,
                    "category_num": 10,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Takes a lead role in planning/implementing programs that seek to further train, motivate and develop staff"
                }
            ]
        }
    },
    "11": {
        "spe_aa_std_categories_id": 48,
        "category_num": 11,
        "category_id": 11,
        "category_title": "PERFORMANCE MANAGEMENT",
        "category_group": "",
        "category_description": "Managing employees performance in alignment with the mission & goals of the organization; giving meaningful and timely feedback; providing training, resources & development opportunities to staff",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 211,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Staff does not have needed resources or training to be successful at job"
                },
                {
                    "spe_aa_std_category_ex_id": 212,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Fails to complete performance appraisal form"
                },
                {
                    "spe_aa_std_category_ex_id": 213,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Fails to assist staff with creating professional development plans"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 214,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs to identify how to better equip staff with resources and training"
                },
                {
                    "spe_aa_std_category_ex_id": 215,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Needs to improve the timeliness and completion of staff performance appraisal form"
                },
                {
                    "spe_aa_std_category_ex_id": 216,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs to be more effective at helping staff create realistic development plans"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 217,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Ensures that staff have the training and resources needed for job success"
                },
                {
                    "spe_aa_std_category_ex_id": 218,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Provides regular feedback to staff on issues relating to their performance and completes performance appraisal as required"
                },
                {
                    "spe_aa_std_category_ex_id": 219,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Effectively assists staff with creating and fulfilling realistic development plans"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 220,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Is dedicated to staff's continuous learning and development at job"
                },
                {
                    "spe_aa_std_category_ex_id": 221,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Meets regularly with staff to review performance and the achievement of individual/ team performance goals"
                },
                {
                    "spe_aa_std_category_ex_id": 222,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Invests time to teach and mentor staff at how to create and complete development plans"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 223,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Inspires staff to learn and be resourceful"
                },
                {
                    "spe_aa_std_category_ex_id": 224,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Always gives meaningful and constructive feedback to staff"
                },
                {
                    "spe_aa_std_category_ex_id": 225,
                    "category_num": 11,
                    "category_id": 11,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Excellent role model for continuous learning & development"
                }
            ]
        }
    },
    "12": {
        "spe_aa_std_categories_id": 49,
        "category_num": 12,
        "category_id": 12,
        "category_title": "ORGANIZATIONAL ACCOUNTABILITY",
        "category_group": "",
        "category_description": "Delegating authority consistent with the UCSD Principles of Accountability",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 226,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Delegates to staff who are not in a position to accept authority"
                },
                {
                    "spe_aa_std_category_ex_id": 227,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Delegates without direction or support"
                },
                {
                    "spe_aa_std_category_ex_id": 228,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Fails to show good judgment in assessing risks when delegating authority"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 229,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs to improve understanding of policies governing appropriate delegation of authority"
                },
                {
                    "spe_aa_std_category_ex_id": 230,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Needs to give clear directions or goals when delegating authority"
                },
                {
                    "spe_aa_std_category_ex_id": 231,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs to improve judgment in weighing risks to organization before delegating authority"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 232,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Delegates fairly and appropriately to staff who are in a position to have authority"
                },
                {
                    "spe_aa_std_category_ex_id": 233,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Considers staff's capabilities before delegating"
                },
                {
                    "spe_aa_std_category_ex_id": 234,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Shows good judgment and weighs risks to organization before delegating authority"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 235,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Uses delegation to develop staff"
                },
                {
                    "spe_aa_std_category_ex_id": 236,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Chooses staff wisely to take control of responsibilities"
                },
                {
                    "spe_aa_std_category_ex_id": 237,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Takes accountability and uses sound judgment to protect the interests of the organization"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 238,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Has strengthened department considerably with effective delegation"
                },
                {
                    "spe_aa_std_category_ex_id": 239,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Makes decisions based on facts and best interests of the organization"
                },
                {
                    "spe_aa_std_category_ex_id": 240,
                    "category_num": 12,
                    "category_id": 12,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Contributes to the success of and takes accountability for the failures of the organization"
                }
            ]
        }
    },
    "13": {
        "spe_aa_std_categories_id": 50,
        "category_num": 13,
        "category_id": 13,
        "category_title": "RESOURCE MANAGEMENT AND PLANNING",
        "category_group": "",
        "category_description": "Managing available resources efficiently to provide the best services possible while enabling employees to achieve their work goals",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 241,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Does not effectively manage University resources to meet the needs of organization"
                },
                {
                    "spe_aa_std_category_ex_id": 242,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Does not consider needs of others when using or managing resources"
                },
                {
                    "spe_aa_std_category_ex_id": 243,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Fails to make contingency plans when faced with budgetary restrictions"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 244,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs to improve management and use of resources to effectively meet organizational objectives"
                },
                {
                    "spe_aa_std_category_ex_id": 245,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Uses resources without informing others"
                },
                {
                    "spe_aa_std_category_ex_id": 246,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Consistently under-anticipates resource needs"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 247,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Treats resources carefully to provide the best services possible to employees and customers"
                },
                {
                    "spe_aa_std_category_ex_id": 248,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Coordinates resource use with others"
                },
                {
                    "spe_aa_std_category_ex_id": 249,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Works within given budget and allocated personnel"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 250,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Suggests ways to save money and resources"
                },
                {
                    "spe_aa_std_category_ex_id": 251,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Uses time and resources effectively with careful consideration of needs outside immediate area of responsibility"
                },
                {
                    "spe_aa_std_category_ex_id": 252,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Anticipates resource needs and makes accommodations before it becomes an issue."
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 253,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Organizes people and resources for maximum efficiency and success"
                },
                {
                    "spe_aa_std_category_ex_id": 254,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Uses innovative ways to save that impact others outside of immediate area of responsibility"
                },
                {
                    "spe_aa_std_category_ex_id": 255,
                    "category_num": 13,
                    "category_id": 13,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Excellent manager and forecaster of resources"
                }
            ]
        }
    },
    "100": {
        "spe_aa_std_categories_id": 43,
        "category_num": 100,
        "category_id": 10,
        "category_title": "PRINCIPLES OF COMMUNITY",
        "category_group": "",
        "category_description": "UCSD strives to maintain a climate of fairness, cooperation and professionalism...",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 136,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Fails to work well with others to create a climate of cooperation and professionalism"
                },
                {
                    "spe_aa_std_category_ex_id": 137,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Has not demonstrated behavior consistent with accepted professional standards"
                },
                {
                    "spe_aa_std_category_ex_id": 138,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Has excluded others due to personal or professional differences"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 139,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs to improve professional relationships with colleagues and other departments"
                },
                {
                    "spe_aa_std_category_ex_id": 140,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Has occasionally offended others with unprofessional behavior"
                },
                {
                    "spe_aa_std_category_ex_id": 141,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Shows favoritism or bias towards others who have similar characteristics"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 142,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Works well with colleagues and other departments"
                },
                {
                    "spe_aa_std_category_ex_id": 143,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Behaves within accepted professional standards"
                },
                {
                    "spe_aa_std_category_ex_id": 144,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Treats others equally regardless of personal differences"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 145,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Will do what it takes to work well with others to create a climate of cooperation and professionalism"
                },
                {
                    "spe_aa_std_category_ex_id": 146,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Is a role model for professional behavior"
                },
                {
                    "spe_aa_std_category_ex_id": 147,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Is inclusive and supportive of others despite personal differences"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 148,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Excellent willingness to help others"
                },
                {
                    "spe_aa_std_category_ex_id": 149,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Coaches others in professional behavior"
                },
                {
                    "spe_aa_std_category_ex_id": 150,
                    "category_num": 100,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Fosters inclusiveness, respect and welcoming environment"
                }
            ]
        }
    },
    "101": {
        "spe_aa_std_categories_id": 44,
        "category_num": 101,
        "category_id": 10,
        "category_title": "DIVERSITY",
        "category_group": "",
        "category_description": "Supporting UCSD diversity values, working without bias, and creating a climate of equal opportunity for all",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 151,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Has demonstrated behavior that is unsupportive of UCSD diversity values"
                },
                {
                    "spe_aa_std_category_ex_id": 152,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Has shown insensitivity or disrespect towards some individuals"
                },
                {
                    "spe_aa_std_category_ex_id": 153,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Has made decisions based on personal attributes, not qualifications or performance"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 154,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs to improve understanding of UCSD diversity values"
                },
                {
                    "spe_aa_std_category_ex_id": 155,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Needs to be more sensitive and respectful of individuals in protected classes"
                },
                {
                    "spe_aa_std_category_ex_id": 156,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs to ensure that all individuals are given equitable opportunities"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 157,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Supports UCSD diversity values"
                },
                {
                    "spe_aa_std_category_ex_id": 158,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Shows no indication of bias"
                },
                {
                    "spe_aa_std_category_ex_id": 159,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Makes decisions based on performance and qualifications"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 160,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Invites and encourages diverse ideas"
                },
                {
                    "spe_aa_std_category_ex_id": 161,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Sensitive to and respectful of all individuals"
                },
                {
                    "spe_aa_std_category_ex_id": 162,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Ensures that all individuals are provided equal opportunities regardless of personal attributes"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 163,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Excellent role model of diversity practices"
                },
                {
                    "spe_aa_std_category_ex_id": 164,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Coaches others in appropriate behavior and diversity awareness"
                },
                {
                    "spe_aa_std_category_ex_id": 165,
                    "category_num": 101,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Implements ideas and programs to promote equal opportunity and diversity awareness"
                }
            ]
        }
    },
    "102": {
        "spe_aa_std_categories_id": 45,
        "category_num": 102,
        "category_id": 10,
        "category_title": "HEALTH AND SAFETY",
        "category_group": "",
        "category_description": "Performing work within health and safety guidelines with care and concern for others, in a healthy and environmentally sound workplace",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 166,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Fails to perform work within safety guidelines"
                },
                {
                    "spe_aa_std_category_ex_id": 167,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Fails to consider the health and safety of others when performing tasks"
                },
                {
                    "spe_aa_std_category_ex_id": 168,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Fails to promote a healthy and environmentally sound workplace"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 169,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs to improve understanding of and adherence to safety guidelines"
                },
                {
                    "spe_aa_std_category_ex_id": 170,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Needs to remember that others may be affected by one's own safety practices"
                },
                {
                    "spe_aa_std_category_ex_id": 171,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs to improve health and safety of immediate workplace"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 172,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Performs all work within safety guidelines"
                },
                {
                    "spe_aa_std_category_ex_id": 173,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Encourages others to be mindful of safe practices"
                },
                {
                    "spe_aa_std_category_ex_id": 174,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Maintains a healthy and safe work environment within area of immediate responsibility"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 175,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Is a role model for safety and environmentally correct practices"
                },
                {
                    "spe_aa_std_category_ex_id": 176,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Shows care and concern for the health and safety of others"
                },
                {
                    "spe_aa_std_category_ex_id": 177,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Gives feedback on how to improve health and safety outside immediate area of responsibility"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 178,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Ensures that others have a safe work environment"
                },
                {
                    "spe_aa_std_category_ex_id": 179,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Coaches/trains others in best practices relating to environment, health and safety"
                },
                {
                    "spe_aa_std_category_ex_id": 180,
                    "category_num": 102,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Develops or implements processes to improve the health and safety of the workplace"
                }
            ]
        }
    },
    "103": {
        "spe_aa_std_categories_id": 46,
        "category_num": 103,
        "category_id": 10,
        "category_title": "CUSTOMER SERVICE",
        "category_group": "",
        "category_description": "Supporting the UCSD philosophy to provide the best possible products and services",
        "scoreInfo": {
            "U": [
                {
                    "spe_aa_std_category_ex_id": 181,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 0,
                    "ex_text": "Fails to demonstrate competent and professional customer service"
                },
                {
                    "spe_aa_std_category_ex_id": 182,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 1,
                    "ex_text": "Is unable or unwilling to help customers resolve issues"
                },
                {
                    "spe_aa_std_category_ex_id": 183,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "U",
                    "ex_num": 2,
                    "ex_text": "Does not respond to customers in a timely manner"
                }
            ],
            "I": [
                {
                    "spe_aa_std_category_ex_id": 184,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 0,
                    "ex_text": "Needs to improve customer relations skills"
                },
                {
                    "spe_aa_std_category_ex_id": 185,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 1,
                    "ex_text": "Is easily annoyed by customers with a lot of questions or needs"
                },
                {
                    "spe_aa_std_category_ex_id": 186,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "I",
                    "ex_num": 2,
                    "ex_text": "Needs to improve response time"
                }
            ],
            "S": [
                {
                    "spe_aa_std_category_ex_id": 187,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 0,
                    "ex_text": "Competent and professional with customers"
                },
                {
                    "spe_aa_std_category_ex_id": 188,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 1,
                    "ex_text": "Courteous and knowledgeable"
                },
                {
                    "spe_aa_std_category_ex_id": 189,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "S",
                    "ex_num": 2,
                    "ex_text": "Responds to customers in a timely manner"
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 190,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Relates to customers exceedingly well"
                },
                {
                    "spe_aa_std_category_ex_id": 191,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Resourceful in finding solutions and resolving customer issues"
                },
                {
                    "spe_aa_std_category_ex_id": 192,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Does not let customers down"
                }
            ],
            "E": [
                {
                    "spe_aa_std_category_ex_id": 193,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 0,
                    "ex_text": "Graceful and tactful under pressure from customers"
                },
                {
                    "spe_aa_std_category_ex_id": 194,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 1,
                    "ex_text": "Always patient, professional and excellent resource for customers"
                },
                {
                    "spe_aa_std_category_ex_id": 195,
                    "category_num": 103,
                    "category_id": 10,
                    "rating": "E",
                    "ex_num": 2,
                    "ex_text": "Has a good reputation with both internal and external customers"
                }
            ]
        }
    },
    "1001": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1001,
        "category_id": 1001,
        "category_title": "CUSTOMER SERVICE",
        "category_group": "",
        "category_description": "A customer is anyone that a UC San Diego employee interacts with, including but not limited to students, fellow staff members, parents, faculty, academics, patients, vendors, affiliates, visitors, and community members. Effective customer service is the ability to meet the needs of others in a timely, efficient, accurate, resourceful, innovative, caring and respectful manner.  To be customer-service oriented is to place customer care at the core and the forefront of our business decisions, to be able to recognize and anticipate customer needs and make every effort to meet and exceed customer expectations. ",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 0,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 1,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 2,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 3,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 4,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 5,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 6,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 7,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 8,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 9,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 10,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 11,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 12,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 13,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 14,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 15,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 16,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 17,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 18,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 19,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 20,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 21,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 22,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 23,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 24,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 25,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 26,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 27,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 28,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 29,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 30,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 31,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 32,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 33,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 34,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    },
    "1002": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1002,
        "category_id": 1002,
        "category_title": "PRINCIPLES OF COMMUNITY",
        "category_group": "",
        "category_description": "To foster the best possible working and learning environment, UC San Diego strives to maintain a climate of equity, fairness, cooperation, and professionalism. All of us are expected to positively contribute to a climate of integrity marked by mutual respect for each other; celebrate diversity and adapt responsibly to cultural differences; seek to foster understanding and acceptance; promote awareness through education; engage in constructive dialogue and strategies for engaging and resolving conflict; reject any and all acts of discrimination of any kind and appropriately confront and respond to such acts; affirm the right to freedom of expression; maintain and promote an atmosphere free of abusive or demeaning treatment, and commit to adherence and enforcement of all UC San Diego policies and procedures that promote the fulfillment of these principles.",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 35,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 36,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 37,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 38,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 39,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 40,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 41,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 42,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 43,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 44,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 45,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 46,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 47,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 48,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 49,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 50,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 51,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 52,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 53,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 54,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 55,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 56,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 57,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 58,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 59,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 60,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 61,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 62,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 63,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 64,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 65,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 66,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 67,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 68,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 69,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    },
    "1003": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1003,
        "category_id": 1003,
        "category_title": "HEALTH AND SAFETY",
        "category_group": "",
        "category_description": "Safety and environmental issues are essential elements of ensuring the continued success of UC San Diego and its employees. To ensure that everyone has an opportunity to contribute to the University�s mission, each of us must be afforded a safe, healthy, and environmentally sound workplace. We are all responsible for ensuring that policies, practices, services, and behaviors support accepted and current safety, health, and environmental standards.",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 70,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 71,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 72,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 73,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 74,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 75,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 76,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 77,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 78,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 79,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 80,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 81,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 82,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 83,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 84,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 85,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 86,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 87,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 88,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 89,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 90,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 91,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 92,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 93,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 94,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 95,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 96,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 97,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 98,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 99,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 100,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 101,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 102,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 103,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 104,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    },
    "1004": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1004,
        "category_id": 1004,
        "category_title": "LEADERSHIP, MANAGEMENT, SUPERVISION",
        "category_group": "",
        "category_description": "UC San Diego managers and designated supervisors play a crucial leadership role ensuring the effectiveness and productivity of their respective units.  Through data analysis, strategic thinking and decision-making regarding current and future use of resources, coaching, mentoring, and day-to-day interface with direct reports and other key stakeholders, the primary role and function of the leader/manager/supervisor is to enable achievement of the mission and goals of the department and University.  This is accomplished through utilizing their knowledge, skills, and subject matter expertise in efforts to support and inspire employees to consistently achieve or surpass performance expectations and/or results.",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 105,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 106,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 107,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 108,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 109,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 110,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 111,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 112,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 113,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 114,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 115,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 116,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 117,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 118,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 119,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 120,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 121,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 122,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 123,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 124,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 125,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 126,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 127,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 128,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 129,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 130,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 131,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 132,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 133,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 134,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 135,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 136,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 137,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 138,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 139,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    },
    "1005": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1005,
        "category_id": 1005,
        "category_title": "STEWARDSHIP OF RESOURCES",
        "category_group": "",
        "category_description": "",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 140,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 141,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 142,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 143,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 144,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 145,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 146,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 147,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 148,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 149,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 150,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 151,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 152,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 153,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 154,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 155,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 156,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 157,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 158,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 159,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 160,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 161,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 162,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 163,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 164,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 165,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 166,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 167,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 168,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 169,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 170,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 171,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 172,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 173,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 174,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    },
    "1006": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1006,
        "category_id": 1006,
        "category_title": "PROGRAM/PROJECT/ PROCESS MANAGEMENT",
        "category_group": "",
        "category_description": "",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 175,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 176,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 177,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 178,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 179,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 180,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 181,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 182,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 183,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 184,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 185,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 186,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 187,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 188,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 189,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 190,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 191,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 192,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 193,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 194,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 195,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 196,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 197,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 198,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 199,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 200,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 201,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 202,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 203,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 204,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 205,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 206,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 207,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 208,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 209,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    },
    "1007": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1007,
        "category_id": 1007,
        "category_title": "ORGANIZATIONAL INSIGHT / BUSINESS JUDGMENT",
        "category_group": "",
        "category_description": "",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 210,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 211,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 212,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 213,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 214,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 215,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 216,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 217,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 218,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 219,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 220,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 221,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 222,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 223,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 224,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 225,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 226,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 227,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 228,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 229,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 230,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 231,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 232,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 233,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 234,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 235,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 236,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 237,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 238,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 239,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 240,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 241,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 242,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 243,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 244,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    },
    "1008": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1008,
        "category_id": 1008,
        "category_title": "COMMUNICATION",
        "category_group": "",
        "category_description": "",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 245,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 246,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 247,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 248,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 249,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 250,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 251,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 252,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 253,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 254,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 255,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 256,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 257,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 258,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 259,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 260,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 261,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 262,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 263,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 264,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 265,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 266,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 267,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 268,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 269,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 270,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 271,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 272,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 273,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 274,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 275,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 276,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 277,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 278,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 279,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    },
    "1009": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1009,
        "category_id": 1009,
        "category_title": "RELATIONSHIP BUILDING",
        "category_group": "",
        "category_description": "",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 280,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 281,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 282,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 283,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 284,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 285,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 286,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 287,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 288,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 289,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 290,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 291,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 292,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 293,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 294,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 295,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 296,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 297,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 298,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 299,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 300,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 301,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 302,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 303,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 304,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 305,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 306,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 307,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 308,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 309,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 310,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 311,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 312,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 313,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 314,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    },
    "1010": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1010,
        "category_id": 1010,
        "category_title": "PERFORMANCE MANAGEMENT",
        "category_group": "",
        "category_description": "",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 315,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 316,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 317,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 318,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 319,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 320,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 321,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 322,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 323,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 324,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 325,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 326,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 327,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 328,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 329,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 330,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 331,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 332,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 333,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 334,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 335,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 336,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 337,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 338,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 339,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 340,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 341,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 342,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 343,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 344,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 345,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 346,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 347,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 348,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 349,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    },
    "1011": {
        "spe_aa_std_categories_id": 999,
        "category_num": 1011,
        "category_id": 1011,
        "category_title": "VALUING EQUITY, DIVERSITY, INCLUSION",
        "category_group": "",
        "category_description": "",
        "scoreInfo": {
            "O": [
                {
                    "spe_aa_std_category_ex_id": 350,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 0,
                    "ex_text": "Consistently takes initiative to engage in an extensive level of research, data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 351,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 1,
                    "ex_text": "Work product and results consistently demonstrate an exceptionally high level of accuracy, attention to detail, thoroughness and is typically error free.  Exhibits exceptional judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 352,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 2,
                    "ex_text": "Routinely volunteers for extra duties when feasible. Effectively manages multiple priorities and maintains a positive, collaborative approach even under challenging or extreme circumstances."
                },
                {
                    "spe_aa_std_category_ex_id": 353,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 3,
                    "ex_text": "Serves as a subject matter expert in key job performance areas - is regularly sought out for input, advice, and counsel by all levels of staff."
                },
                {
                    "spe_aa_std_category_ex_id": 354,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 4,
                    "ex_text": "Demonstrates an extraordinarily high level of self-discipline, self-direction, and initiative. Works independently and autonomously, requiring minimal to no direction or supervision. Proactively seeks supervision and direction when needed."
                },
                {
                    "spe_aa_std_category_ex_id": 355,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 5,
                    "ex_text": "Consistently and proactively identifies opportunities for improvement..  Constantly makes effort to reach and maintain best practice in own work and takes initiative to gather resources, materials, and feedback to improve.  Volunteers to lead and/or support process or business improvement efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 356,
                    "category_num": 0,
                    "category_id": 0,
                    "rating": "O",
                    "ex_num": 6,
                    "ex_text": "Consistently and proactively seeks and identifies opportunities for positive change. Frequently puts forth ideas and volunteers to take on the role of change sponsor, agent, or advocate."
                }
            ],
            "M": [
                {
                    "spe_aa_std_category_ex_id": 357,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 0,
                    "ex_text": "Frequently takes initiative to engage in detailed data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 358,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 1,
                    "ex_text": "Work demonstrates a high level of accuracy, attention to detail, and thoroughness. Exhibits good judgment in problem-solving and decision-making.."
                },
                {
                    "spe_aa_std_category_ex_id": 359,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 2,
                    "ex_text": "Readily and willingly assumes additional responsibilities when asked. Able to manage multiple tasks and prioritize with minimal direction."
                },
                {
                    "spe_aa_std_category_ex_id": 360,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 3,
                    "ex_text": "Viewed as a �go to� person among peers � frequently recognized among peers for job knowledge and expertise.  Consistently demonstrates sound judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 361,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 4,
                    "ex_text": "Demonstrates high level of self-discipline, self-direction, and initiative. Works independently, requiring minimal supervision. Willingly assumes accountability and responsibility."
                },
                {
                    "spe_aa_std_category_ex_id": 362,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 5,
                    "ex_text": "Frequently takes the initiative to identify opportunities for improvement. Proactively reviews own work quantity and quality and requests feedback, coaching, and resources in efforts to achieve higher performance levels."
                },
                {
                    "spe_aa_std_category_ex_id": 363,
                    "category_num": 1,
                    "category_id": 1,
                    "rating": "M",
                    "ex_num": 6,
                    "ex_text": "Actively demonstrates support when change is proposed or implemented; willingly agrees to serve in the role of change sponsor, agent and/or advocate when opportunities are presented."
                }
            ],
            "A": [
                {
                    "spe_aa_std_category_ex_id": 364,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 0,
                    "ex_text": "Takes initiative to engage in data analysis and strategic thinking in efforts to help examine or make recommendations or decisions regarding current and/or future use of staff, materials, technology, space and equipment resources,  in direct support of current and future strategic goals and objectives."
                },
                {
                    "spe_aa_std_category_ex_id": 365,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 1,
                    "ex_text": "Performs job duties at expected levels. Work products demonstrate appropriate level of accuracy, attention to detail, and completeness. Demonstrates appropriate level of judgment in problem-solving and decision-making."
                },
                {
                    "spe_aa_std_category_ex_id": 366,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 2,
                    "ex_text": "Extends extra effort when necessary to accomplish or complete tasks, meet deadlines."
                },
                {
                    "spe_aa_std_category_ex_id": 367,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 3,
                    "ex_text": "Demonstrates expected level of job knowledge. Positively contributes to the success of coworkers and overall department goals."
                },
                {
                    "spe_aa_std_category_ex_id": 368,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 4,
                    "ex_text": "Demonstrates appropriate level of self-direction, self-discipline and initiative. Takes ownership of work quality and quantity and responds accurately and appropriately to questions - readily engages in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 369,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 5,
                    "ex_text": "Willingly engages in efforts toward improvement. Requests and openly accepts coaching and feedback � makes efforts to incorporate suggestions for improvement or change into work tasks and responsibilities."
                },
                {
                    "spe_aa_std_category_ex_id": 370,
                    "category_num": 2,
                    "category_id": 2,
                    "rating": "A",
                    "ex_num": 6,
                    "ex_text": "Demonstrates openness to change and adapts with a positive attitude."
                }
            ],
            "P": [
                {
                    "spe_aa_std_category_ex_id": 371,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 0,
                    "ex_text": "Rarely initiates or engages in efforts to examine work issues or make recommendations unless asked to do so by supervisor.  Has occasional difficulty identifying obstacles, changing work needs, or engaging in problem-solving."
                },
                {
                    "spe_aa_std_category_ex_id": 372,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 1,
                    "ex_text": "Work is sometimes inaccurate or incomplete, creating the need for additional quality review from others.  "
                },
                {
                    "spe_aa_std_category_ex_id": 373,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 2,
                    "ex_text": "Requires repeated coaching and instruction from supervisor in order to perform or complete job tasks at acceptable levels in a timely manner."
                },
                {
                    "spe_aa_std_category_ex_id": 374,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 3,
                    "ex_text": "Often requests or demonstrates the need for assistance from coworkers in order to understand or complete routine job tasks. Demonstrates  inconsistent ability to perform at acceptable levels."
                },
                {
                    "spe_aa_std_category_ex_id": 375,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 4,
                    "ex_text": "Rarely Initiates but will engage in problem-solving when requested to do so by supervisor."
                },
                {
                    "spe_aa_std_category_ex_id": 376,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 5,
                    "ex_text": "Rarely identifies or recognizes opportunities for improvement unless asked to do so by supervisor. Demonstrates reluctance to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 377,
                    "category_num": 3,
                    "category_id": 3,
                    "rating": "P",
                    "ex_num": 6,
                    "ex_text": "Demonstrates difficulty accepting and/or adapting to change; Occasionally engages in behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ],
            "N": [
                {
                    "spe_aa_std_category_ex_id": 378,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 0,
                    "ex_text": "Unable to effectively problem-solve or adequately respond to requests, obstacles or changing needs."
                },
                {
                    "spe_aa_std_category_ex_id": 379,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 1,
                    "ex_text": "Work is frequently inaccurate, incomplete, with little to no demonstrated attention to detail."
                },
                {
                    "spe_aa_std_category_ex_id": 380,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 2,
                    "ex_text": "Requires excessive amount of instruction, guidance and supervision in order to perform or complete routine job tasks."
                },
                {
                    "spe_aa_std_category_ex_id": 381,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 3,
                    "ex_text": " Unable to demonstrate acceptable level of job knowledge. Makes frequent errors and/or mistakes in judgment, despite coaching or retraining efforts."
                },
                {
                    "spe_aa_std_category_ex_id": 382,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 4,
                    "ex_text": "Unable and/or unwilling to recognize or accept responsibility for work issues of concern."
                },
                {
                    "spe_aa_std_category_ex_id": 383,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 5,
                    "ex_text": "Frequently responds to performance improvement efforts or requests in a negative manner. Unable and/or unwilling to accept constructive feedback or take ownership of the need for improvement."
                },
                {
                    "spe_aa_std_category_ex_id": 384,
                    "category_num": 4,
                    "category_id": 4,
                    "rating": "N",
                    "ex_num": 6,
                    "ex_text": "Unable and/or unwilling to accept or adapt to change; consistently demonstrates behaviors that are contentious, divisive, and/or uncooperative when faced with the need for change."
                }
            ]
        }
    }
};
