export enum RatableType {
  Function = "function",
  Category = "category",
  Overall  = "overall"
}

export class RatingOption {
  rating: string;
  ratingHtml: Array<string>;
}

export class Rating {
  rating: string;
  importance: string;
}

export class ScoreOption {
  code: string;
  description: string;
}

export const G3_SCORES: ScoreOption[] = [
  { code: "T", description: "(T) N/A"},
  { code: "E", description: "(E) Excellent"},
  { code: "A", description: "(A) Above Expectations"},
  { code: "S", description: "(S) Satisfactory"},
  { code: "I", description: "(I) Improvement Needed"},
  { code: "U", description: "(U) Unsatisfactory"}
];

export const G100_SCORES: ScoreOption[] =  [
  { code: "T", description: "(T) N/A"},
  { code: "O", description: "(O) OUTSTANDING ACHIEVEMENT"},
  { code: "M", description: "(M) ACHIEVED MORE... "},
  { code: "A", description: "(A) ACHIEVED EXPECTATIONS..."},
  { code: "P", description: "(P) PARTIALLY ACHIEVED..."},
  { code: "N", description: "(N) DID NOT ACHIEVE..."}
];

export function getScoreOptions(structureVersion: string) {
  console.log("getScoreOptions called with " + structureVersion);
  if ( structureVersion === "g3") {
    return G3_SCORES;
  } else if ( structureVersion === "g100") {
    return G100_SCORES;
  } else {
    console.log("ERROR: Invalid evalVersion in getScoreOptions: " + structureVersion);
    return G3_SCORES;
  }
}

export class Ratable {
  ratableType: RatableType = RatableType.Function;
  structureVersion: string;
  evalId:       string;
  ratableId:    string;
  title:        string;
  description:  string;

  importance:   string;
  rating:       string;
  ratingMod:    string;
  noModFlag?:   boolean;
  noImportanceFlag?: boolean;

  scoreOptions: ScoreOption[];
  ratingOptions: Array<string> = [];
  ratingInfo:    Array<RatingOption>;

}
