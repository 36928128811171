import { MessageType, MessageEvent } from "./message-bus.service";

export class TextDialogCancelMessage {
  msg: string;

  static event(aMessage: string): MessageEvent<TextDialogCancelMessage> {
    return {
      messageType: MessageType.TextDialogCancel,
      data: { msg: aMessage}
    };
  }
}
