import { catsDb } from "./catsDb";
import { mockEvals } from "./mock.evals";
import { evcStandards } from "./evc_standards";
import { genericStandards } from "./generic_standards";
import { genericG100Standards } from "./generic_g100_standards";

export const nextStateMap = {
    "SD": "CH",
    "CH": "CE",
    "CE": "FI",
    "FI": "FI"
};

export const catInfo = catsDb;
export const standards = evcStandards;
export const defaultStandards = genericStandards;
export const evaluations = mockEvals;
export const defaultG100Standards = genericG100Standards;