import { Component, OnInit, Input } from "@angular/core";
import { MessageBusService, MessageType } from "../shared/message-bus/message-bus.service";

@Component({
  selector: "perf-review",
  templateUrl: "./perf-review.component.html",
  styleUrls: ["./perf-review.component.css"]
})
export class PerfReviewComponent implements OnInit {

  @Input() evaluation;
  @Input() mode;
  @Input() title = "";
  @Input() titleDescription = "";

  constructor(private messageBus: MessageBusService) { }

  ngOnInit() {
  }

  beginEdit(data: any, viewOnly: boolean): void {
    data["viewOnly"] = viewOnly;
    this.messageBus.publish({
      messageType: MessageType.BeginRatableEdit,
      data: data
    });
  }

  /*
  beginInfoDialog(data: any, viewOnly: boolean): void {
    this.messageBus.publish({
      messageType: MessageType.OpenRatableInfoDialog,
      data: data
    })
  }
  */

}
