export class User {

  static empty: User = {
    empno: 0,
    lname: "",
    fname: "",
    deptCode: "",
    hrRole: false
  };

  empno: number;
  lname: string;
  fname: string;
  deptCode: string;
  hrRole: boolean;
}
