import { NgModule }  from '@angular/core';
import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { G3EvalComponent } from "./g3-eval/g3-eval.component";
import { G100EvalComponent } from "./g100-eval/g100-eval.component";
import { HomeComponent } from "./home/home.component";

export const routes: Routes = [
    { path: "g3/:id/:mode", component: G3EvalComponent },
    { path: "g100/:id/:mode", component: G100EvalComponent },
    { path: "home", component: HomeComponent },
    { path: "admin", loadChildren: "app/admin/admin.module#AdminModule" },
    { path: "**",  redirectTo: "home" },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
