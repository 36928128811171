import { Component, OnInit, Input } from "@angular/core";
import { FormControl, FormGroup} from "@angular/forms";


import { MessageBusService } from "../shared/message-bus/message-bus.service";
import { TextSaveStartMessage } from "../shared/message-bus/text-save-start-message.type";
import { TextDialogCancelMessage } from "../shared/message-bus/text-dialog-cancel-message.type";

@Component({
  selector: 'eval-text-editor',
  templateUrl: './eval-text-editor.component.html',
  styleUrls: ['./eval-text-editor.component.css']
})
export class EvalTextEditorComponent implements OnInit {

  @Input() employeeName: string;
  @Input() title: string;

  formGroup: FormGroup;

  private _textValue: string;
  private _fieldName: string;
  private _id: string;

  @Input() set id(anId: string) {
    this._id = anId;
    this.formGroup.patchValue({ id: this._id});
  }
  get id () { return this._id } 

  @Input() set fieldName(aName: string) {
    this._fieldName = aName;
    this.formGroup.patchValue({ fieldName: this._fieldName});
  }
  get fieldName() { return this._fieldName } 

  @Input() set textValue(someText: string) {
    console.log("text value changed to " + someText);
    this._textValue = someText;
    this.formGroup.patchValue({ textValue: this._textValue });
  }

  get textValue() { return this._textValue; }


  constructor(private messageBus: MessageBusService) { }

  ngOnInit() {
    this.formGroup = new FormGroup({
      textValue:  new FormControl("placeholder"),
      fieldName:  new FormControl("fieldName"),
      id:         new FormControl("id")
    });
  }

  onSubmit() {
    console.log("onSubmit() called; values are: ");
    console.log(this.formGroup.value);
    this.messageBus.publish(TextSaveStartMessage.event(this.formGroup.value))
  }

  dialogCancel() {
    this.messageBus.publish(TextDialogCancelMessage.event("cancelled"));
    console.log("dialogCancel() called");
  }
}