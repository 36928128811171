import { Component, OnInit } from "@angular/core";
import { DbService } from "./db.service";
import { User } from "./shared/user.type";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  user: User = User.empty;

  constructor(private dbService: DbService) {}

  ngOnInit() {
    this.dbService.getCurrentUser().subscribe(
      user => this.user = user,
      error => console.log("error getting current user " + JSON.stringify(error))
    );
  }
}
